/**
 * ChatWindow Component
 *
 * Description:
 * The ChatWindow component is a container for the chat interface in the application.
 * It includes the MessageList, which shows a list of messages, and the MessageInput to
 * allow users to send new messages. An options menu can be displayed at the top of the chat,
 * and a CloseButton allows users to close the chat window. This component also interacts
 * with the ChatGPT API to send messages and receive responses.
 */

import type { FC, JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineRestore } from 'react-icons/md';
import MessageInput from '@/components/common/CopilotChat/CopilotChatComponents/MessageInput';
import MessageList from '@/components/common/CopilotChat/CopilotChatComponents/MessageList';
import OptionMenu from '@/components/common/CopilotChat/CopilotChatComponents/OptionMenu';
import CloseButtonSvg from '@/miscellaneous/Images/CopilotIcons/CloseButtonSvg';
import styles from '@/styles/scss/copilotChat.module.scss';
import { useChatStore } from '@/utils/hooks/useChatStore';
import { useGPTQuery } from '@/utils/hooks/useGptQuery';
import { usePromptTextToSpeech } from '@/utils/hooks/usePromptTextToSpeech';
interface IChatWindowProps {
  closeChatWindow: () => void;
}

/**
 * ChatWindow component responsible for rendering the chat interface and managing user interactions.
 * @param toggleDrawer - Function to toggle the chat drawer's visibility.
 * @returns The ChatWindow component.
 */
const ChatWindow: FC<IChatWindowProps> = ({
  closeChatWindow
}): JSX.Element => {
  const {
    messages,
    addMessage,
    setMessages,
    deleteMessage,
    firstMessage,
    resetFirstMessage,
    promptType,
    setPromptType
  } = useChatStore();
  const [prompt, setPrompt] = useState('');
  const [thinking, setThinking] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    sendMessage,
    data,
    error,
    resetMessageState
  } = useGPTQuery();
  const [isClearChat, setIsClearChat] = useState(false);
  const {
    convertPromptToSpeech
  } = usePromptTextToSpeech();

  /**
   * Handles the change of the input prompt for the user's message.
   * @param e - The change event of the input.
   */
  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  /**
   * Adds a user message to the chat, sends it to the ChatGPT API, and sets the chat to a loading state.
   * @param message - The user's message.
   */
  const addMessageToChat = (message: string, chatDisplay?: string) => {
    addMessage(chatDisplay || message, 'user');
    setPrompt('');
    sendMessage(message);
    setIsDisabled(true);
    setThinking(true);
    setIsClearChat(false);
  };

  // add a useEffect that takes triggers addMessageToChat with first message when firstMessage is not null
  useEffect(() => {
    if (firstMessage?.message) {
      addMessageToChat(firstMessage.message, firstMessage.messageDisplay);
      resetFirstMessage();
    }
  }, [firstMessage]);

  /**
   * Finds a message in the chat history based on its ID.
   * @param id - The ID of the message to find.
   * @returns The found message.
   */
  const findMessage = (id: string) => {
    return messages.find(message => message.id === id);
  };
  const findMessageIndex = (id: string) => {
    return messages.findIndex(message => message.id === id);
  };

  /**
   * Adds an option to the chat, replaces the original message with the option, and sends it to the ChatGPT API.
   * @param option - The selected option.
   * @param id - The ID of the message to replace with the option.
   */
  const addOptionToChat = (option: string, id: any) => {
    const message = findMessage(id);
    sendMessage(`${message?.text} ${option}`);
    const newList = [...messages];
    newList.splice(findMessageIndex(id) + 1);
    setMessages(newList);
    deleteMessage(id);
    setIsClearChat(false);
    setIsDisabled(true);
    setThinking(true);
  };

  /**
   * Clears the chat history.
   */
  const clearChat = () => {
    resetMessageState();
    setThinking(false);
    setIsDisabled(false);
    setIsClearChat(true);
  };

  /**
   * Updates the chat with the response from the ChatGPT API when data is received.
   */
  useEffect(() => {
    // Skip processing if no data or chat is being cleared
    if (!data || isClearChat) return;
    if (promptType === 'runway') {
      // For runway prompts: convert both messages to speech before displaying
      convertPromptToSpeech(messages[messages.length - 1]?.text || '', data, promptType, '').finally(() => {
        addMessage(data, 'bot'); // Add bot message to chat
        setThinking(false); // Hide loading state
        setIsDisabled(false); // Enable input
        // reset the prompt type to default
        setPromptType('default');
      });
      return;
    }

    // For standard prompts: directly add bot message and reset states
    addMessage(data, 'bot'); // Add bot message to chat
    setThinking(false); // Hide loading state
    setIsDisabled(false); // Enable input
  }, [data]);
  const messagesEndRef = useRef(null);
  return <div className={styles.chatWindow} data-sentry-component="ChatWindow" data-sentry-source-file="ChatWindow.tsx">
      <div className={styles.chatHeader}>
        <h1 className={styles.headerText}>AI Financial Chat </h1>
        <div className={styles.chatHeaderIcons}>
          <span onClick={clearChat}>
            <MdOutlineRestore size={22} data-sentry-element="MdOutlineRestore" data-sentry-source-file="ChatWindow.tsx" />
          </span>
          <span onClick={closeChatWindow}>
            <CloseButtonSvg data-sentry-element="CloseButtonSvg" data-sentry-source-file="ChatWindow.tsx" />
          </span>
        </div>
      </div>
      <div className="h-full">
        <div ref={messagesEndRef} className={styles.chatContainer}>
          {messages.length === 0 ? <OptionMenu addMessageToChat={addMessageToChat} /> : <MessageList messages={messages} addOptionToChat={addOptionToChat} setIsDisabled={setIsDisabled} error={error} thinking={thinking} messagesEndRef={messagesEndRef} />}
        </div>
        <div className="fixed bottom-0 flex w-full items-center justify-center bg-white pb-3">
          <MessageInput addMessageToChat={addMessageToChat} prompt={prompt} handlePromptChange={handlePromptChange} disabled={thinking || isDisabled} data-sentry-element="MessageInput" data-sentry-source-file="ChatWindow.tsx" />
        </div>
      </div>
    </div>;
};
export default ChatWindow;