/* eslint-disable @typescript-eslint/no-shadow */
import { Check, CircleEllipsis, Plus } from 'lucide-react';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import Popup from 'reactjs-popup';
import type { CustomTemplate } from '@/components/Settings/Template/components/AddTemplatePopup/AddTemplatePopup';
import type { CompanyState } from '../CompanyOnboardPopup/onboardingPopupTypes';
import HelpButton from '../HelpButton/HelpButton';
import styles from './genericStagePopup.module.scss';
export type StepObject = {
  label: string;
  icon?: JSX.Element;
  checkIcon?: JSX.Element;
  route?: string;
};
export type Steps = StepObject[];

/**
 * A reusable component that renders the header for a generic stage popup, including a title and a close button.
 *
 * @param close - A function to be called when the close button is clicked.
 * @param title - The title to be displayed in the header.
 * @returns A React component that renders the header for a generic stage popup.
 */
const GenericStagePopupHeader = ({
  close,
  title,
  isOnboarding = false,
  additionalHeaderContent
}: {
  close: (isClosePopup?: boolean) => void;
  title: string;
  isOnboarding?: boolean;
  additionalHeaderContent?: JSX.Element;
}) => {
  return <div className={styles.genericStagePopupHeader} data-sentry-component="GenericStagePopupHeader" data-sentry-source-file="GenericStagePopup.tsx">
      <div className=" flex items-center gap-2">
        <h1>{title}</h1>
        {isOnboarding && <HelpButton widgets={['elfsight-app-d4aec96f-32f6-4ed9-a3c7-2deda81d0b95']} />}
      </div>

      {additionalHeaderContent || <Plus onClick={() => close(false)} className="rotate-45" size={22} />}
    </div>;
};

/**
 * A reusable component that renders the navigation for a multi-step stage popup.
 *
 * @param activeStep - The currently active step in the stage UI.
 * @param setActiveStep - Function to update the active step.
 * @param steps - An array of step objects, each with a label, icon, and check icon.
 */
const GenericStagePopupNavigation = ({
  activeStep,
  setActiveStep,
  steps,
  navigateToStep: navigateToStepHandler,
  complatedStepCount,
  isShowStep,
  sidebarBottomContent
}: {
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  steps: Steps;
  navigateToStep?: (step: StepObject) => void;
  complatedStepCount?: number;
  isShowStep: boolean;
  sidebarBottomContent?: JSX.Element | undefined;
}) => {
  /**
   * Navigates to the specified step in the stage UI.
   *
   * @param step - The step object containing the label of the step to navigate to.
   * @returns Void
   */
  const navigateToStep = (step: StepObject) => {
    const currentStepIndex = steps.findIndex((item: StepObject) => (item.route || item.label) === activeStep);
    const targetStepIndex = steps.findIndex((item: StepObject) => (item.route || item.label) === step.label);
    if (targetStepIndex <= currentStepIndex + 1) {
      setActiveStep(step.route || step.label);
    }
  };
  return <div className={styles.genericStagePopupNavigation} data-sentry-component="GenericStagePopupNavigation" data-sentry-source-file="GenericStagePopup.tsx">
      <ul className="shadow-none">
        {steps.map((item: StepObject, index: number) => {
        const step = item.route || item.label;
        const activeStepIndex = complatedStepCount || steps.findIndex(step => (step.route || step.label) === activeStep);
        const isCompletedStep = index < activeStepIndex;
        const isActive = step === activeStep;
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
          // eslint-disable-next-line react/no-array-index-key
          key={index} onClick={() => navigateToStepHandler ? navigateToStepHandler(item) : navigateToStep(item)} className={isActive ? styles.activeNavigation : isCompletedStep ? styles.completedNavigation : isShowStep ? styles.defaultNavigation : styles.hideNavigation}>
              {isCompletedStep ? item.checkIcon || <Check size={16} /> : item.icon || <CircleEllipsis size={16} />}
              <span>{item.label}</span>
            </li>
        );
      })}
      </ul>

      {sidebarBottomContent}
    </div>;
};

/**
 * A reusable component that renders the content for a multi-step stage popup.
 *
 * @param activeStep - The currently active step in the stage UI.
 * @param setActiveStep - Function to update the active step.
 * @param close - Function to close the popup.
 * @param stageComponent - The React component that renders the content for the active step.
 * @param stageState - The state object for the active step component.
 * @param setStageState - Function to update the state of the active step component.
 * @param steps - An array of step objects, each with a label, icon, and check icon.
 */
const GenericStagePopupContent = ({
  activeStep,
  setActiveStep,
  close,
  stageComponent,
  stageState,
  setStageState,
  steps,
  navigateToStep,
  complatedStepCount,
  setIsShowStep,
  isShowStep,
  hideCloseButton,
  sidebarBottomContent
}: {
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  close: (isClosePopup: boolean) => void;
  stageComponent: React.FC<any>;
  stageState: CustomTemplate | CompanyState;
  setStageState: (state: CustomTemplate | CompanyState) => void;
  steps: Steps;
  navigateToStep?: (step: StepObject) => void;
  complatedStepCount?: number;
  setIsShowStep: Dispatch<SetStateAction<boolean>>;
  isShowStep: boolean;
  hideCloseButton?: boolean;
  sidebarBottomContent?: JSX.Element | undefined;
}) => {
  return <div className="flex justify-between" data-testid="generic-stage-popup-content" data-sentry-component="GenericStagePopupContent" data-sentry-source-file="GenericStagePopup.tsx">
      <GenericStagePopupNavigation activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} navigateToStep={navigateToStep} complatedStepCount={complatedStepCount} isShowStep={isShowStep} sidebarBottomContent={sidebarBottomContent} data-sentry-element="GenericStagePopupNavigation" data-sentry-source-file="GenericStagePopup.tsx" />

      {/* 
        Render the active step component with the provided props.
       */}
      {React.createElement(stageComponent, {
      activeStep,
      setActiveStep,
      close,
      stageState,
      setStageState,
      steps,
      setIsShowStep,
      hideCloseButton
    })}
    </div>;
};

/**
 * A reusable popup component that displays a multi-step stage UI.
 *
 * @param poupStyles - Optional custom styles for the popup content.
 * @param overlayStyles - Optional custom styles for the popup overlay.
 * @param trigger - The JSX element that triggers the popup.
 * @param activeStep - The currently active step in the stage UI.
 * @param setActiveStep - Function to update the active step.
 * @param resetStep - Function to reset the active step.
 * @param steps - An array of step objects, each with a label, icon, and check icon.
 * @param stageComponent - The React component that renders the content for the active step.
 * @param stageState - The state object for the active step component.
 * @param setStageState - Function to update the state of the active step component.
 * @param title - The title to display in the popup header.
 */
const GenericStagePopup = ({
  poupStyles,
  overlayStyles,
  activeStep,
  setActiveStep,
  resetStep,
  steps,
  stageComponent,
  stageState,
  setStageState,
  title,
  trigger,
  isModal,
  open,
  hideCloseButton = false,
  navigateToStep,
  complatedStepCount,
  isOnboarding,
  additionalHeaderContent,
  closeOnDocumentClick = false,
  onOpen,
  sidebarBottomContent
}: {
  poupStyles: React.CSSProperties | undefined;
  overlayStyles: React.CSSProperties | undefined;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  resetStep: (isClosePopup?: boolean) => void;
  steps: Steps;
  stageComponent: React.FC<any>;
  stageState: CustomTemplate | CompanyState;
  setStageState: (state: CustomTemplate | CompanyState) => void;
  title: string;
  trigger?: JSX.Element;
  isModal?: boolean; // Determines whether the popup is a modal or a popup
  open?: boolean; // Determines whether the modal is open or closed
  hideCloseButton?: boolean;
  navigateToStep?: (step: StepObject) => void;
  complatedStepCount?: number;
  isOnboarding?: boolean;
  additionalHeaderContent?: JSX.Element | undefined;
  closeOnDocumentClick?: boolean;
  onOpen?: () => void;
  sidebarBottomContent?: JSX.Element | undefined;
}) => {
  //  closePopup is a function that closes the popup and resets the step
  const closePopup = (close: () => void, isClosePopup?: boolean) => {
    if (!isModal) close();
    resetStep(isClosePopup);
  };
  const [isShowStep, setIsShowStep] = React.useState(true);

  /**
   * Generates the appropriate modal or popup component based on the `isModal` prop.
   *
   * @param func - A function that returns the modal or popup content.
   * @returns The modal or popup component with the appropriate props.
   */
  const generateModalType = (func: (close: any) => JSX.Element) => {
    const commonProps = {
      contentStyle: poupStyles,
      overlayStyle: overlayStyles,
      closeOnDocumentClick,
      modal: true
    };
    return isModal ? <Popup {...commonProps} open={open} closeOnEscape={false} onClose={() => {
      resetStep();
    }} onOpen={onOpen} data-sentry-element="Popup" data-sentry-component="generateModalType" data-sentry-source-file="GenericStagePopup.tsx">
        {
      // @ts-ignore
      (close: any) => {
        return func(close);
      }}
      </Popup> : <Popup {...commonProps} trigger={trigger} closeOnEscape={false} onOpen={onOpen} data-sentry-element="Popup" data-sentry-component="generateModalType" data-sentry-source-file="GenericStagePopup.tsx">
        {
      // @ts-ignore
      (close: any) => {
        return func(close);
      }}
      </Popup>;
  };

  /**
   * Generates the content for the modal or popup component based on the `isModal` prop.
   *
   * @param close - A function that closes the modal or popup.
   * @returns The content for the modal or popup component.
   */
  const generateModalContent = (close: any) => {
    return <>
        <GenericStagePopupHeader close={(isClosePopup?: boolean) => closePopup(close, isClosePopup)} title={title} isOnboarding={isOnboarding} additionalHeaderContent={additionalHeaderContent} data-sentry-element="GenericStagePopupHeader" data-sentry-source-file="GenericStagePopup.tsx" />
        <GenericStagePopupContent activeStep={activeStep} setActiveStep={setActiveStep} close={(isClosePopup: boolean) => closePopup(close, isClosePopup)} stageComponent={stageComponent} hideCloseButton={hideCloseButton} stageState={stageState} setStageState={setStageState} steps={steps} navigateToStep={navigateToStep} complatedStepCount={complatedStepCount} setIsShowStep={setIsShowStep} isShowStep={isShowStep} sidebarBottomContent={sidebarBottomContent} data-sentry-element="GenericStagePopupContent" data-sentry-source-file="GenericStagePopup.tsx" />
      </>;
  };
  return <>{generateModalType(generateModalContent)}</>;
};
export default GenericStagePopup;