/* eslint-disable import/no-cycle */
import { dateToNumber, type FormulaEntries } from '@/components/modelCalculations/modelUtils/modelUtils';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { parseUTCDateObject } from '@/utils/dateUtils';
import type { CalculatedEmployeeData } from '@/utils/HiringPlanUtils';
import type { ExpressionType, Formula } from '@/utils/types/formulaTypes';
import { FormulaGroupType, FormulaInputType } from '@/utils/types/formulaTypes';
import type { CalculationDepartment, Department } from '@/utils/types/HiringTypes';
const departments: CalculationDepartment[] = ['gna', 'rnd', 'snm', 'cogs', 'total'];
// create a list of numbers from 1 to 100
export const SystemFormulaIdsRange = 300; // every id below this number is a system formula
export const HPFormulaDateRange = 20; // every id below this number is a HR calculation formula

const genericFormulaSharedValues = {
  created_at: '2021-09-01',
  updated_at: '2021-09-01',
  group: FormulaGroupType.Calculations,
  expression_string: '',
  model_id: 1,
  input_type: FormulaInputType.Number,
  output_id: null,
  branch_id: 1,
  emoji: '',
  parent_id: null
};
function getSumOfChildrenFormulas(date: string, _formulaId?: number, formulaEntries?: FormulaEntries, currentRowFormula?: Formula): {
  sum: number;
  childrenCount: number;
} {
  const {
    formulaParentMap
  } = CalculationsStore.getState();
  const rowFormulaID = currentRowFormula?.id || 0;
  const children = formulaParentMap?.[rowFormulaID || 0];
  if (children && children.length > 0) {
    const result = children.map((childId: number) => {
      return formulaEntries?.[childId]?.[date] || 0;
    });
    return {
      sum: result.reduce((a: number, b: number) => a + b, 0),
      childrenCount: result.length
    };
  }
  return {
    sum: 0,
    childrenCount: 0
  };
}
export const systemFormulas: Formula[] = [{
  id: 21,
  name: 'Current Column Month',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: (date: string) => {
    return dateToNumber(date);
  }
}, {
  id: 105,
  name: 'Current Date: Month number',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: () => {
    const {
      currentDate
    } = useZustandStore.getState();
    const d = parseUTCDateObject(currentDate);
    // if d is valid date return the month (1-12)
    if (!Number.isNaN(d.getTime())) {
      return d.getMonth() + 1;
    }
    return 0;
  }
}, {
  id: 101,
  name: 'Current Month Number',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: (date: string) => {
    const d = parseUTCDateObject(date);
    // if d is valid date return the month
    if (!Number.isNaN(d.getTime())) {
      return d.getMonth() + 1;
    }
    return 0;
  }
}, {
  id: 102,
  name: 'Current Year Number',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: (date: string) => {
    const d = parseUTCDateObject(date);
    // if d is valid date return the year
    if (!Number.isNaN(d.getTime())) {
      return d.getFullYear();
    }
    return 0;
  }
}, {
  id: 103,
  name: 'All Children Formulas:SUM',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: (date: string, formulaId?: number, formulaEntries?: FormulaEntries, currentRowFormula?: Formula) => getSumOfChildrenFormulas(date, formulaId, formulaEntries, currentRowFormula)?.sum || 0
}, {
  id: 104,
  name: 'All Children Formulas:AVG',
  ...genericFormulaSharedValues,
  group: FormulaGroupType.Inputs,
  evalFunction: (date: string, formulaId?: number, formulaEntries?: FormulaEntries, currentRowFormula?: Formula) => {
    const {
      sum,
      childrenCount
    } = getSumOfChildrenFormulas(date, formulaId, formulaEntries, currentRowFormula);
    return childrenCount > 0 ? Number((sum / childrenCount).toFixed(3)) : 0;
  }
}];
export const allChildrenFormulasIDSum = 103;
export const allChildrenFormulasIDAvg = 104;
export const allChildrenFormulasIDSumFormula: ExpressionType = {
  formula: `{{${allChildrenFormulasIDSum},y,x}}`,
  start: '',
  end: ''
};
export const allChildrenFormulasIDAvgFormula: ExpressionType = {
  formula: `{{${allChildrenFormulasIDAvg},y,x}}`,
  start: '',
  end: ''
};
interface SystemFormulaMap {
  [key: number]: Formula;
}
export const systemFormulasMap: SystemFormulaMap = systemFormulas.reduce((acc: SystemFormulaMap, cur: Formula) => {
  acc[cur.id] = cur;
  return acc;
}, {});
export type HPCalculationConfig = {
  hiringCalculationType?: 'headcount' | 'newHires' | 'salary';
  department?: string;
  isContractor?: boolean;
};
const payrollOutputID = 1;
const BaseHPFormulas: Formula[] = [
// create a list of formulas for each HP formulas, for each formula we need to create a formula object per department and a total formula summing up all 4 formulas.
//   {
//     id: 1,
//     name: 'New Hires',
//     ...genericFormulaSharedValues,
//     hiringCalculationConfig: {
//       hiringCalculationType: 'newHires',
//     },
//   },
{
  id: 2,
  name: 'Employee Count',
  ...genericFormulaSharedValues,
  hiringCalculationConfig: {
    hiringCalculationType: 'headcount'
  }
}, {
  id: 3,
  name: 'Total Base Salary',
  ...genericFormulaSharedValues,
  output_id: payrollOutputID,
  hiringCalculationConfig: {
    hiringCalculationType: 'salary'
  }
}];
let currentId = 0;
const HPFormulas: Formula[] = departments.map(department => {
  return BaseHPFormulas.map(formula => {
    currentId += 1;
    const strDepartment = department.toUpperCase().replace('N', '&').replace('TOTAL', '');
    const name = strDepartment === '' ? formula.name : `${formula.name}: ${strDepartment}`;
    return {
      ...formula,
      id: currentId,
      name,
      hiringCalculationConfig: {
        ...formula.hiringCalculationConfig,
        department
      }
    };
  });
}).flat();
export function GetHPFormulas(branchId: number, outputId: number): Formula[] {
  return HPFormulas.map(formula => {
    const newFormula = ({
      ...formula
    } as Formula);
    newFormula.branch_id = branchId;
    const curConfig = formula.hiringCalculationConfig;
    if (curConfig?.hiringCalculationType === 'salary' && curConfig?.department !== 'total') {
      newFormula.output_id = outputId;
    }
    return newFormula;
  });
}

// write a function that takes a HPFormula, a date in the format YYYY-MM-DD and returns the value of that formula at that date according to the HPCalculationConfig of the formula
export function calculateHPFormulaAtDate(formula: Formula, date: string, calculatedEmployeeData: CalculatedEmployeeData): number {
  const {
    hiringCalculationConfig
  } = formula;
  if (!hiringCalculationConfig) {
    console.error(`Error calculating formula, formula is:`, formula);
    return 0;
  }
  const {
    department,
    hiringCalculationType
  } = hiringCalculationConfig;
  if (!department || !hiringCalculationType) {
    console.error(`Error calculating formula, hiringCalculationConfig is:`, hiringCalculationConfig);
    return 0;
  }
  // define helper functions for headcount
  const getHeadcountValueAtDate = (curDep: string, curDate: string) => {
    return calculatedEmployeeData.headCountByDepartment[(curDep as Department)][curDate] || 0;
  };
  // define helper functions for salary
  const getSalaryValueAtDate = (curDep: string, curDate: string) => {
    return calculatedEmployeeData.costByDepartment[(curDep as Department)][curDate] || 0;
  };
  // the month key should be changed to MM-YYYY format, it is YYYY-MM-DD format
  const dateSplitted = date.split('-');
  const monthKey = `${dateSplitted[1]}-${dateSplitted[0]}`;

  // create switch case for hiringCalculationType, if department is 'total' you need to sum up all 4 departments into the value
  switch (hiringCalculationType) {
    case 'headcount':
      if (department === 'total') {
        return departments.reduce((acc, curDep) => {
          if (curDep === 'total') return acc;
          return acc + getHeadcountValueAtDate(curDep, monthKey);
        }, 0);
      }
      return getHeadcountValueAtDate(department, monthKey);
    case 'salary':
      if (department === 'total') {
        return departments.reduce((acc, curDep) => {
          if (curDep === 'total') return acc;
          const curVal = getSalaryValueAtDate(curDep, monthKey);
          return acc + curVal;
        }, 0);
      }
      return getSalaryValueAtDate(department, monthKey);
    default:
      return 0;
  }
}