/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line simple-import-sort/imports
import { BarChartBig, CircleCheck, Ellipsis, Landmark, LayoutPanelLeft, ListEnd, PieChart, UsersRound } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { MdKeyboardCommandKey } from 'react-icons/md';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getTimeAgoFromUTCDate, parseUTCDateObject } from '@/utils/dateUtils';
import HelpButton from '@/components/common/HelpButton/HelpButton';
import dynamicRoute from '@/miscellaneous/constant';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import useGetConnectors from '@/utils/hooks/useGetConnectors/useGetConnectors';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { Oval } from 'react-loader-spinner';
import { useShallow } from 'zustand/react/shallow';
import DataStore from '@/miscellaneous/store/DataStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import { Tooltip } from 'react-tooltip';
import { useMemo, useCallback } from 'react';
import styles from './integrations.module.scss';
// eslint-disable-next-line import/no-cycle

interface TabContentProps {
  title: string;
  color: string;
  icon: JSX.Element;
  count: number;
}

// TabContent is a component that displays the content of a tab
const TabContent: React.FC<TabContentProps> = ({
  title,
  color,
  icon,
  count
}) => <div className={styles.tabContent} data-sentry-component="TabContent" data-sentry-source-file="Integrations.tsx">
    <div style={{
    backgroundColor: color
  }}>{icon}</div>
    <h1>{title}</h1>
    <span>{count}</span>
  </div>;
interface EmptyIntegrationProps {
  title: string;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// EmptyIntegration is a component that displays an empty state for integrations
const EmptyIntegration: React.FC<EmptyIntegrationProps> = ({
  title,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  return <div className={styles.emptyIntegration} data-sentry-component="EmptyIntegration" data-sentry-source-file="Integrations.tsx">
      <h1>
        {title} {translate('integrations')}
      </h1>
      <p>{translate('no_integrations_connected')}</p>
      <button onClick={() => {
      // setIsIntegrationPopUpOpen is a function that sets the isIntegrationPopUpOpen state to true
      if (setIsIntegrationPopUpOpen) setIsIntegrationPopUpOpen(true);
    }} type="button" className={styles.addIntegrationButton}>
        <FiPlus className="me-2" size={16} data-sentry-element="FiPlus" data-sentry-source-file="Integrations.tsx" />
        {translate('new_integration')}
      </button>
    </div>;
};
interface IntegrationBoxProps {
  item: any;
  isIntegrationPopUpOpen?: boolean;
  onConnectIntegration?: (item: any) => void;
  isConnected?: boolean;
  disableSettingsIcon?: boolean;
}

// findRelevantIcon is a function that takes an item as input and returns the icon for the item
export const findRelevantIcon = (item: Integration, connectorsData: ApiDeckConnector[]) => {
  // connectorsData is an array of ApiDeckConnector objects
  if (connectorsData && connectorsData.length > 0) {
    // find the icon for the item
    const icon = connectorsData.find((dataItem: ApiDeckConnector) => dataItem.service_id === item.service_id);
    return icon?.icon;
  }
  return <MdKeyboardCommandKey size={50} data-sentry-element="MdKeyboardCommandKey" data-sentry-component="findRelevantIcon" data-sentry-source-file="Integrations.tsx" />;
};
interface ConnectButtonProps {
  isShow: boolean;
  item: ApiDeckConnector;
  onAction: (item: ApiDeckConnector) => void;
  isConnected?: boolean;
}
export const IntegrationConnectButton: React.FC<ConnectButtonProps> = ({
  isShow,
  item,
  onAction,
  isConnected = false
}) => {
  const {
    data
  } = useIntegrations();
  const {
    t: translate
  } = useTranslation(['integrations']);
  if (!isShow) return <span />;
  const dataOfItemType = data?.find((datum: Integration) => datum.type === item.type);
  const isDisabled = !isConnected && !!dataOfItemType;
  const tooltipContent = isDisabled ? 'integrations:you_have_connected_integrations' : 'integrations:connectIntegration';
  const buttonText = isConnected ? 'integrations:connected' : 'integrations:connectIntegration';
  const buttonClassName = `${styles.integrationConnectBtn} ${isConnected ? styles.connected : ''} ${isDisabled ? styles.disabled : ''}`;
  return <>
      <Tooltip id="integration-btn-tooltip" place="top" data-sentry-element="Tooltip" data-sentry-source-file="Integrations.tsx" />
      <div className="flex w-full items-center justify-center pt-5">
        <button onClick={() => {
        if (!isDisabled && !isConnected) {
          onAction?.(item);
        }
      }} type="button" className={buttonClassName} data-tooltip-id="integration-btn-tooltip" data-tooltip-content={translate(tooltipContent)}>
          {isConnected && <CircleCheck size={16} />}
          {translate(buttonText)}
        </button>
      </div>
    </>;
};

// IntegrationBox is a component that displays an integration box
export const IntegrationBox: React.FC<IntegrationBoxProps> = ({
  item,
  isIntegrationPopUpOpen,
  onConnectIntegration,
  isConnected = false,
  disableSettingsIcon
}) => {
  const router = useRouter();
  const {
    t: translate
  } = useTranslation(['integrations']);
  const {
    connectorsData
  } = useGetConnectors();
  const {
    activeCompany,
    activeBranch,
    integrationsSyncing
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      activeCompany: state.activeCompany,
      activeBranch: state.activeBranch,
      integrationsSyncing: state.integrationsSyncing
    };
  }));
  const currentIntegrationSyncing = integrationsSyncing?.[item?.id];
  const integrationSyncingData = !item?.latest_fetch_date || currentIntegrationSyncing; // if latest_fetch_date is null or currentIntegrationSyncing is true, set integrationSyncingData to true

  return <div onClick={() => {
    if (!isIntegrationPopUpOpen) {
      router.push(`/${activeCompany?.slug}/${activeBranch?.slug}/${dynamicRoute.integrations}/${item.id}`);
    }
  }} className={styles.integrationBox} data-sentry-component="IntegrationBox" data-sentry-source-file="Integrations.tsx">
      <div className={styles.integrationBoxHeader}>
        <div className={styles.integrationBoxIcon}>
          {findRelevantIcon(item, (connectorsData as ApiDeckConnector[])) ? <img src={(findRelevantIcon(item, (connectorsData as ApiDeckConnector[])) as string)} alt="icon" width="50" height="50" /> : <MdKeyboardCommandKey size={50} />}
        </div>
        <div className={styles.integrationBoxTitle}>
          <h1>
            {_.capitalize(item.service_id) || _.capitalize(`${item.type} Integration`) || 'Integration'}
          </h1>
          <p>
            {/* 
              If isIntegrationPopUpOpen is true, display the unified_api and integration name.
              Otherwise, display the latest_fetch_date and a message indicating that no fetch data is available.
             */}

            {isIntegrationPopUpOpen && `${item.unified_api} ${translate('integration')}`}
            {!isIntegrationPopUpOpen && (!integrationSyncingData ? getTimeAgoFromUTCDate(parseUTCDateObject(item.latest_fetch_date)) : <span className="flex items-center gap-2">
                  <Oval visible height="16" width="16" strokeWidth={4} color={colors.slateGray} secondaryColor={colors.midBlue} ariaLabel="oval-loading" />
                  {translate('fetching')}
                </span>)}
          </p>
        </div>
        {!disableSettingsIcon && <Ellipsis className={`${styles.integrationSettings} rotate-90`} />}
      </div>
      <IntegrationConnectButton isShow={(isIntegrationPopUpOpen as boolean)} item={item} onAction={(onConnectIntegration as (item: ApiDeckConnector) => void)} isConnected={isConnected} data-sentry-element="IntegrationConnectButton" data-sentry-source-file="Integrations.tsx" />
    </div>;
};
interface TabPanelContentProps {
  data: any;
  title: string;
  isIntegrationPopUpOpen: boolean;
  onConnectIntegration?: (item: any) => void;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// TabPanelContent is a component that displays the content of a tab panel
const TabPanelContent: React.FC<TabPanelContentProps> = ({
  data,
  title,
  isIntegrationPopUpOpen,
  onConnectIntegration,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  return <div className={styles.tabPanelContent} data-sentry-component="TabPanelContent" data-sentry-source-file="Integrations.tsx">
      {data.length === 0 && !isIntegrationPopUpOpen ? <EmptyIntegration title={title} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} /> : <>
          <div className={styles.tabPanelContentHeader}>
            <h1>
              {title} {translate('integrations')}
            </h1>
          </div>
          <div className={styles.tabPanelContentBody}>
            {data.map((item: any) => <IntegrationBox key={item.id} item={item} isIntegrationPopUpOpen={isIntegrationPopUpOpen} onConnectIntegration={onConnectIntegration} />)}
          </div>
        </>}
    </div>;
};
interface IntegrationPageBodyProps {
  isIntegrationPopUpOpen: boolean;
  apiDeckData?: any;
  onConnectIntegration?: (item: any) => void;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// Extracted tab configuration to a separate constant
const TAB_CONFIG = ([{
  title: 'all',
  color: colors.brightBlueSky,
  icon: <LayoutPanelLeft size={18} />,
  type: null
}, {
  title: 'accounting',
  color: colors.brightBlue,
  icon: <PieChart size={18} />,
  type: 'accounting'
}, {
  title: 'banks',
  color: colors.vividOrange2,
  icon: <Landmark size={18} />,
  type: 'banks'
}, {
  title: 'payroll',
  color: colors.lightPink1,
  icon: <UsersRound size={18} />,
  type: 'payroll'
}, {
  title: 'hris',
  color: colors.lightYellow5,
  icon: <BarChartBig size={18} />,
  type: 'hris'
}, {
  title: 'crm',
  color: colors.strongMintGreen,
  icon: <ListEnd size={18} />,
  type: 'crm'
}] as const);
export const IntegrationPageBody: React.FC<IntegrationPageBodyProps> = ({
  isIntegrationPopUpOpen,
  apiDeckData,
  onConnectIntegration,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany
  })));
  const {
    activeIntegrations: data
  } = DataStore(useShallow(state => ({
    activeIntegrations: state.activeIntegrations
  })));

  // integrationData is a memoized value that contains the data for the integrations
  const integrationData = useMemo(() => {
    if (!apiDeckData && !data) return [];
    if (apiDeckData && data) {
      return apiDeckData.map((item: ApiDeckConnector, index: number) => ({
        ...item,
        type: data[index]?.type
      }));
    }
    return apiDeckData || data;
  }, [apiDeckData, data]);

  // filterDataByType is a function that filters the data by type
  const filterDataByType = useCallback((type: string | null) => {
    if (!type) return integrationData || [];
    const normalizedType = type.toLowerCase();
    if (apiDeckData && data) {
      return integrationData?.filter((item: ApiDeckConnector) => item?.unified_api?.toLowerCase() === normalizedType) || [];
    }
    if (apiDeckData) {
      return apiDeckData.filter((item: ApiDeckConnector) => item?.unified_api?.toLowerCase() === normalizedType) || [];
    }
    return data?.filter((item: Integration) => item?.type?.toLowerCase() === normalizedType) || [];
  }, [apiDeckData, data, integrationData]);

  // tabList is a memoized value that contains the tab list
  const tabList = useMemo(() => {
    return TAB_CONFIG.map(tab => ({
      ...tab,
      title: translate(tab.title),
      count: tab.type ? filterDataByType(tab.type).length : apiDeckData?.length || data?.length || 0
    }));
  }, [translate, filterDataByType, apiDeckData, data]);

  // renderTabs is a function that renders the tabs
  const renderTabs = () => <TabList data-sentry-element="TabList" data-sentry-component="renderTabs" data-sentry-source-file="Integrations.tsx">
      {tabList.map((tab, index) => <Tab key={index}>
          <TabContent title={tab.title} color={tab.color || ''} icon={tab.icon} count={tab.count} />
        </Tab>)}
    </TabList>;

  // renderTabPanels is a function that renders the tab panels
  const renderTabPanels = () => <>
      {tabList.map((tab, index) => <TabPanel key={index}>
          <TabPanelContent data={tab.type ? filterDataByType(tab.type) : integrationData || []} title={tab.title} isIntegrationPopUpOpen={isIntegrationPopUpOpen} onConnectIntegration={onConnectIntegration} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} />
        </TabPanel>)}
    </>;
  return <div className={styles.integrationCard} data-sentry-component="IntegrationPageBody" data-sentry-source-file="Integrations.tsx">
      {!isIntegrationPopUpOpen && <div className={styles.integrationCardHeader}>
          <div className="flex items-center">
            <h1>{activeCompany?.name}</h1>
          </div>
        </div>}
      <div className={styles.integrationCardBody}>
        <Tabs data-sentry-element="Tabs" data-sentry-source-file="Integrations.tsx">
          {renderTabs()}
          {renderTabPanels()}
        </Tabs>
      </div>
    </div>;
};

// Integrations is a component that displays the integrations page
const Integrations: React.FC = () => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const {
    isIntegrationPopUpOpen,
    setIsIntegrationPopUpOpen
  } = useUtilsStore(useShallow(state => ({
    isIntegrationPopUpOpen: state.isIntegrationPopUpOpen,
    setIsIntegrationPopUpOpen: state.setIsIntegrationPopUpOpen
  })));
  return <div className={styles.integrations} data-sentry-component="Integrations" data-sentry-source-file="Integrations.tsx">
      <div className={styles.integrationsHeader}>
        <div className="flex items-center gap-2">
          <h1>{translate('integrations')}</h1>
          <HelpButton widgets={['elfsight-app-84b7f6fe-32db-4e9b-b139-5d7bc078c05a']} data-sentry-element="HelpButton" data-sentry-source-file="Integrations.tsx" />
        </div>

        <div className="flex items-center">
          <HiMagnifyingGlass className={`${styles.searchIcon} hidden`} size={24} data-sentry-element="HiMagnifyingGlass" data-sentry-source-file="Integrations.tsx" />
          <button onClick={() => setIsIntegrationPopUpOpen(true)} type="button" className={styles.addIntegrationButton}>
            <FiPlus className="me-2" size={16} data-sentry-element="FiPlus" data-sentry-source-file="Integrations.tsx" />
            {translate('new_integration')}
          </button>
        </div>
      </div>

      <div className={styles.integrationsContent}>
        <IntegrationPageBody isIntegrationPopUpOpen={isIntegrationPopUpOpen} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} data-sentry-element="IntegrationPageBody" data-sentry-source-file="Integrations.tsx" />
      </div>
    </div>;
};
export default Integrations;