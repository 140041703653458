/* eslint-disable unused-imports/no-unused-vars */
// ignore unused variables eslint or impoorts
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useShallow } from 'zustand/react/shallow';
import TableLoader from '@/components/common/Loaders/tableLoader';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
// import type { DataStoreState } from '@/miscellaneous/store/DataStore';
// import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import useMutations from '@/utils/hooks/mutations/useMutations';

// eslint-disable-next-line import/no-cycle
import Table from '../shared/table/table';
import sharedColumnSettings from '../shared/TableHelpers/sharedColumnSettings';
import type { TableRow } from '../shared/types';
// eslint-disable-next-line import/no-cycle
import ModelsColumnGenerator from './columnGenerator';
// eslint-disable-next-line import/no-cycle
import processedData from './processedData';

// Create Context
// Create a context for the table

export const ModelsTableContext = React.createContext({});

/**
 * Represents a table component for managing models
 * retrieves formulas and disaplyes them in a table
 * @returns {JSX.Element} - Returns a JSX element representing the hiring plan table.
 */
const ModelsTable = () => {
  // const { branchOutputs, accountTrees } = DataStore(
  //   (state: DataStoreState) => state,
  // );
  const {
    query
  } = useRouter();
  const {
    activeModelFormulas,
    monthlyDates,
    activeModel,
    activeModelDates
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeModelFormulas: state.activeModelFormulas,
    monthlyDates: state.monthlyDates,
    activeModel: state.activeModel,
    activeModelDates: state.activeModelDates
  })));
  const {
    createFormula,
    deleteFormula,
    updateFormula,
    updateModels
  } = useMutations();
  const [columns, setColumns] = useState<any>([]);
  const [hasTriedLoading, setHasTriedLoading] = useState(false);
  const [data, setData] = useState<TableRow[]>([]);
  const [selectedFormula, setSelectedFormula] = useState<any>(null);
  const {
    t
  } = useTranslation('models');
  const [newRowIndex, setNewRowIndex] = useState('');
  const formulaEntries = CalculationsStore(useShallow((state: CalculationsStoreState) => state.formulaEntries));
  const isLoading = !activeModelFormulas;
  useEffect(() => {
    if (isLoading) return;
    // add a console.error here to see what caused the useEffect to run
    // data update
    const newData = processedData((activeModelDates || monthlyDates) ?? [], activeModelFormulas ?? [], t, formulaEntries, activeModel?.id, activeModel?.sort_order);
    setData((newData as TableRow[]));
    setHasTriedLoading(true);
  }, [activeModelDates, activeModelFormulas, formulaEntries]);
  useEffect(() => {
    // columns update
    if (isLoading) return;
    const newColumns = ModelsColumnGenerator({
      createFormula,
      deleteFormula,
      updateFormula,
      setNewRowIndex,
      updateModels
    }, (activeModelDates || monthlyDates) ?? []);
    setColumns(newColumns);
    setHasTriedLoading(true);
  }, [activeModelDates, query?.model]);
  const memoizedValue = useMemo(() => ({
    data,
    setData,
    selectedFormula,
    setSelectedFormula,
    newRowIndex,
    setNewRowIndex
  }), [data, selectedFormula]);
  return !isLoading && hasTriedLoading && data.length > 0 ? <ModelsTableContext.Provider value={memoizedValue} data-sentry-element="unknown" data-sentry-component="ModelsTable" data-sentry-source-file="index.tsx">
      <Tooltip style={{
      zIndex: 9999
    }} id="my-tooltip" data-sentry-element="Tooltip" data-sentry-source-file="index.tsx" />

      <Table data={data} columns={columns} settings={sharedColumnSettings} hideCogIcon headerTitle={activeModel?.name ?? 'Models Table'} enableDnd data-sentry-element="Table" data-sentry-source-file="index.tsx" />
    </ModelsTableContext.Provider> : <TableLoader data-sentry-element="TableLoader" data-sentry-component="ModelsTable" data-sentry-source-file="index.tsx" />;
};
export default ModelsTable;