import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import DataStore from '@/miscellaneous/store/DataStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
type SubscriptionStatus = 'trialing' | 'past_due' | 'unpaid' | 'unpaid' | 'active' | 'incomplete' | 'incomplete_expired' | 'paused';
export type SubscriptionDetails = {
  id: string;
  status: SubscriptionStatus;
  name: string;
};
const useSubscription = () => {
  const {
    companyId
  } = useZustandStore(useShallow(state => ({
    companyId: state.activeCompany?.id
  })));
  const {
    setSubscriptionDetails
  } = DataStore(useShallow(state => ({
    setSubscriptionDetails: state.setSubscriptionDetails
  })));
  const queryObject = useQuery<SubscriptionDetails[] | null>({
    queryKey: [QUERYKEYS.SUBSCRIPTION_STATUS, companyId],
    queryFn: ApiUtils.getSubscriptionStatus,
    enabled: !!companyId,
    refetchInterval: 60000 // refetch every 60 seconds
  });
  useEffect(() => {
    if (queryObject.isSuccess && !queryObject.isLoading && queryObject.data) {
      if (queryObject.data.length > 0) {
        setSubscriptionDetails((queryObject.data[0] as SubscriptionDetails));
      } else {
        setSubscriptionDetails(null);
      }
    } else {
      setSubscriptionDetails(null);
    }
  }, [queryObject.data, queryObject.isLoading, queryObject.isSuccess]);
  return queryObject;
};
export default useSubscription;