/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import dynamicRoute from '@/miscellaneous/constant';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { QUERYKEYS } from '../../../miscellaneous/constant/reactQueryKeyConfig';

/**
 * Interface representing date-related information for a user
 */
interface Dates {
  end_date?: string;
  start_date?: string;
  current_date?: string;
}

/**
 * Interface representing the user data structure returned from the API
 */
export interface UserJson {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  dates?: Dates;
  image?: string;
  is_superuser?: boolean;
}

/**
 * Custom hook to fetch and manage the current user's data
 *
 * @returns {Object} An object containing:
 *   - userMe: Current user data
 *   - isLoading: Loading state of the query
 *   - error: Any error that occurred during the query
 *
 * @example
 * const { userMe, isLoading, error } = useGetUserMe();
 *
 * if (isLoading) return <Loading />;
 * if (error) return <Error />;
 * return <div>{userMe.full_name}</div>;
 */
const useGetUserMe = () => {
  const {
    userMe,
    setUserMe,
    isLoggedIn
  } = useZustandStore(useShallow((state: ZustandState) => ({
    isLoggedIn: state.isLoggedIn,
    userMe: state.userMe,
    setUserMe: state.setUserMe,
    setCurrentDate: state.setCurrentDate,
    setEndDate: state.setEndDate
  })));
  const router = useRouter();
  const {
    data,
    isLoading,
    error
  } = useQuery<UserJson>({
    queryKey: [QUERYKEYS.USER_ME, isLoggedIn?.username],
    queryFn: () => ApiUtils.getUserMe(),
    enabled: Boolean(isLoggedIn)
  });

  // Handle successful data fetch
  const handleSuccess = (data: UserJson) => {
    setUserMe(data);
  };

  // Handle error during data fetch
  const handleError = (error: unknown) => {
    const axiosError = (error as AxiosError);

    // Exit early if error doesn't need handling
    if (!axiosError?.message || router.pathname.includes(dynamicRoute.accessError)) return;

    // Construct error payload
    const errorPayload = {
      message: axiosError.message,
      responseURL: axiosError.request?.responseURL,
      requestPayload: axiosError.request?.data || axiosError.config?.data,
      status: axiosError.response?.status,
      statusText: axiosError.response?.statusText,
      responseData: axiosError.response?.data
    };

    // Redirect to error page with encoded payload
    const errorUrl = `${dynamicRoute.accessError}/?error_description=${encodeURIComponent(JSON.stringify(errorPayload))}`;
    router.push(errorUrl);
  };

  // Effect to handle data fetch results
  useEffect(() => {
    if (isLoading) return;
    if (data && !error) {
      handleSuccess(data);
      return;
    }
    if (error) {
      handleError(error);
    }
  }, [data, isLoading, error]);
  return {
    userMe,
    isLoading,
    error
  };
};
export default useGetUserMe;