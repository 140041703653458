/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */

import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getColumnKeysForDate, getDateStringFormat, getNextMonthKey, parseUTCDateObject } from '@/utils/dateUtils';
import type { AccountValues } from '@/utils/hooks/Calculations/useEntries';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { CulmutativeEnum, Report, ReportItem, ReportSettings } from '@/utils/hooks/reports/reportTypes';
import { GlobalRegex } from '@/utils/regex';

// eslint-disable-next-line import/no-cycle
import { isDebug } from '../../financials/renderRows';
// eslint-disable-next-line import/no-self-import
import type { TableRow } from '../types';

/**
 *  sort items by sort order string and id
 *  */
export function sortItemsByID<T extends {
  id: number;
}>(items: T[], sortOrder: string): any[] {
  const orderMap = sortOrder?.split('|').reduce((acc, id, index) => {
    acc[parseInt(id, 10)] = index; // Parse string ID to number
    return acc;
  }, ({} as Record<number, number>));
  return items.sort((a, b) => {
    const orderA = orderMap[a.id] ?? 100; // Use 100 for undefined fields to put them last
    const orderB = orderMap[b.id] ?? 100;
    return orderA - orderB;
  });
}

/**
 * A function that adds row values of columns to another row
 * @param fromRow The row to add values from
 * @param toRow the row to add values to
 * @param sign to multiply the row valus by -1 or 1
 */
export function addDateValuesFromRowToRow(fromRow: TableRow, toRow: TableRow, sign: number = 1) {
  const dateColumnsRegex = GlobalRegex.anyDateColumnRegex;
  // todo handle adding only date keys and q,total year and total.
  Object.keys(fromRow).forEach(key => {
    const isDateColumn = dateColumnsRegex.test(key);
    if (isDateColumn) {
      if (!fromRow[key] && fromRow[key] !== 0) {
        return;
      }
      if (!toRow[key]) {
        toRow[key] = 0;
      }
      toRow[key] += (fromRow[key] || 0) * sign;
    }
  });
}

/**
 * Adds entry data to the given rows array based on the report settings.
 *
 * Sorts the entries array by timestamp.
 * Then loops through each entry, calculating totals for the date, quarter, year,
 * and overall based on the report settings.
 * Updates the corresponding keys on each row object with the entry amounts.
 *
 * @param report - The report object containing settings: {showTotal: true, showYears: true, showMonths: true, showQuarters: true, showEmptyAccounts: false}
 * @param rows - Array of row objects to update with entry data
 * @param entries - Array of entry objects to add to rows
 * @param sign - Multiplier for entry amounts (defaults to 1)
 * @param rowCulmutativeCalculationType - The type of calculation to use for row values (defaults to null)
 * @param dates - Array of dates to pull the culmutative values over to the end of the range
 * @returns Updated rows array with entry data added
 */
export function addEntriesToRows(rows: TableRow[], entries: AccountValues, report?: Report, sign: number = 1, rowCulmutativeCalculationType: CulmutativeEnum | null = null): TableRow[] {
  // Determine settings based on the report’s existence
  // When report is not provided, act as if only showMonths is true
  const settings = report ? (report.settings as ReportSettings) : {
    showMonths: true,
    showQuarters: false,
    showYears: false,
    showTotal: false
  };
  const {
    showQuarters,
    showYears,
    showTotal
  } = settings;
  let firstMonthKey: string = '';
  let dateKey: string = '';
  let quarterKey: string = '';
  let totalYearKey: string = '';
  let sumAmount = 0;
  const {
    fullTimelineMonthlyDates,
    startDate,
    endDate
  } = useZustandStore.getState();
  const startDateKey = getDateStringFormat(parseUTCDateObject(startDate));
  const endDateKey = getDateStringFormat(parseUTCDateObject(endDate));
  const isDateInUserViewRange = (date: string) => {
    return date >= startDateKey && date <= endDateKey;
  };
  let entryDateKeys = Object.keys(entries);
  entryDateKeys.sort(); // Sort the entry keys by date

  if (rowCulmutativeCalculationType) {
    // checking if need to pull culmutative value all the way to the end
    entryDateKeys = fullTimelineMonthlyDates;
  }
  entryDateKeys.forEach((date: string) => {
    if (!firstMonthKey) firstMonthKey = date;
    const currentValue = entries[date] || 0; // value at date
    if (!currentValue && !sumAmount) return; // if we dont have a value and we are not at an entry summation return
    const entryAmountWithSign = Number(currentValue) * sign;
    if (rowCulmutativeCalculationType) sumAmount += entryAmountWithSign;
    const {
      isoDateKey: _m,
      quarterKey: _q,
      totalYearKey: _y
    } = getColumnKeysForDate(date);
    dateKey = _m;
    quarterKey = _q;
    totalYearKey = _y;
    const curDateValue = rowCulmutativeCalculationType //  If the rowCulmutativeCalculationType is set, use it to calculate the row value
    ? sumAmount : entryAmountWithSign;
    rows.forEach((row: TableRow) => {
      // Conditionally add data for the month
      // if (showMonths) { we always calculate the monthly values because the rest of the calcualtions depend on them.
      if (!rowCulmutativeCalculationType || rowCulmutativeCalculationType === 'end') row[dateKey] = (row[dateKey] || 0) + curDateValue;else {
        row[getNextMonthKey(dateKey)] = curDateValue;
      }
      if (isDateInUserViewRange(dateKey)) {
        // all summed up cols like q,y,total are only to be calculated if the date is in the user view range

        // Conditionally add data for the quarter
        if (showQuarters) {
          if (!rowCulmutativeCalculationType) row[quarterKey] = (row[quarterKey] || 0) + curDateValue;else {
            row[quarterKey] = rowCulmutativeCalculationType === 'end' ? row[dateKey] : row[getNextMonthKey(dateKey)] || row[dateKey];
          }
        }
        // Conditionally add data for the year total
        if (showYears) {
          if (!rowCulmutativeCalculationType) row[totalYearKey] = (row[totalYearKey] || 0) + curDateValue;else {
            row[totalYearKey] = rowCulmutativeCalculationType === 'end' ? row[dateKey] : row[getNextMonthKey(dateKey)] || row[dateKey];
          }
        }
        // Conditionally add overall total data
        if (showTotal) {
          row.Total = rowCulmutativeCalculationType ? row[dateKey] : (row?.Total || 0) + curDateValue;
        }
      }
    });
  });
  return rows;
}

// This function takes the last subrow of a section and adds the values to the total row
export function addLastChildRowValuesToRow(fromRow: TableRow, toRow: TableRow, currentSection?: ReportItem) {
  if (fromRow && fromRow.subRows && fromRow.subRows.length > 0) {
    const lastSubRow: TableRow = fromRow?.subRows[fromRow.subRows.length - 1] ?? {
      id: ''
    };
    if (!lastSubRow.id) {
      if (isDebug) console.log('🚀 ~ lastSubRow missing:', currentSection);
      return; // if last subrow is empty return
    }
    addDateValuesFromRowToRow(lastSubRow, toRow);
  }
} // This function takes the values of a row and adds them to the total row

export function sortItemsByFieldName<T extends Record<string, any>>(items: T[], sortOrder: string, fieldToSortBy: string): any[] {
  const orderMap = sortOrder?.split('|').reduce((acc, id, index) => {
    acc[parseInt(id, 10)] = index; // Parse string ID to number
    return acc;
  }, ({} as Record<number, number>));
  return items.sort((a, b) => {
    const aField = a[fieldToSortBy];
    const bField = b[fieldToSortBy];
    const aId = aField?.id ?? 0;
    const bId = bField?.id ?? 0;
    const orderA = orderMap?.[aId] ?? 100; // Use 100 for undefined fields
    const orderB = orderMap?.[bId] ?? 100;

    // Prioritize items in the order
    if (orderA !== orderB) return orderA - orderB;
    return 0; // else equal order
  });
}
type CreateAccountTreeRowProps = {
  item?: ReportItem;
  accountTreeLevel: number;
  currentRowObject: Output;
  tableDepth: number;
};
export function createAccountTreeRow({
  item,
  accountTreeLevel,
  currentRowObject,
  tableDepth
}: CreateAccountTreeRowProps): TableRow {
  return {
    sidebar: currentRowObject.name,
    id: `accountTree-${currentRowObject.id.toString()}-report-item-${item?.id ?? 0}`,
    type: accountTreeLevel === 0 ? 'accountTree' : 'output',
    subRows: [],
    original_row_object: currentRowObject,
    baseRowItemParent: item,
    depth: tableDepth,
    rowStyle: {
      fontWeight: 'bold'
    }
  };
}
type GetSectionRowProps = {
  currentSection: ReportItem;
  sign: number;
  depth: number;
  branch_id: number;
};
export function getSectionRow({
  currentSection,
  sign,
  depth,
  branch_id
}: GetSectionRowProps): TableRow {
  return {
    sidebar: `${currentSection.name}`,
    id: `section-parent-${currentSection.id}`,
    emptyCellsRow: false,
    // isTotalRow: true,
    type: 'section',
    subRows: [],
    rowStyle: {
      color: colors.black,
      backgroundColor: colors.culturedGray
      // make it bold
    },
    original_row_object: {
      ...currentSection,
      branch_id,
      sign
    },
    baseRowItemParent: currentSection,
    depth
  };
}

/**
 * Checks if the provided `TableRow` object has any keys that match date-related regular expressions.
 *
 * @param row - The `TableRow` object to check.
 * @returns `true` if any keys in the `row` object match the date-related regular expressions, `false` otherwise.
 */
export function rowHasDates(row: TableRow) {
  return Object.keys(row).some(key => key.match(GlobalRegex.dateRegex) || key.match(GlobalRegex.totalDateRegex) || key.match(GlobalRegex.quarterDateRegex));
}
export function getformulaTableRow(item: ReportItem | undefined, curFormula: any, tableDepth: number): TableRow {
  return {
    sidebar: curFormula?.name,
    id: `formula-${item?.formulaId}-row-${item?.id}`,
    type: 'formula',
    original_row_object: curFormula,
    // setting the report item to the row object
    emptyCellsRow: false,
    subRows: [],
    depth: tableDepth,
    baseRowItemParent: item
  };
}
export function multiplyDateValues(obj: TableRow, multiplier: number) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Loop through the keys of the object
  Object.keys(obj).forEach(key => {
    if (dateRegex.test(key) && typeof obj[key] === 'number') {
      if (obj[key] === 0) {
        obj[key] = 0;
      } else {
        obj[key] *= multiplier;
      }
    }
  });
  return obj;
}
// // Test 2: Sort items with nested objects
// const items2 = [
//   { a: { id: 3 }, b: 1 },
//   { a: { id: 1 }, b: 2 },
//   { a: { id: 2 }, b: 3 },
// ];
// const sortOrder2 = '2|1|3';
// const expected2 = [
//   { a: { id: 2 }, b: 3 },
//   { a: { id: 1 }, b: 2 },
//   { a: { id: 3 }, b: 1 },
// ];

// const result2 = sortItemsByFieldName(items2, sortOrder2, 'a'); // result2:[{"a":{"id":1},"b":2},{"a":{"id":3},"b":1},{"a":{"id":2},"b":3}] false
// console.log(
//   `Test 2:${JSON.stringify(result2)}`,
//   JSON.stringify(result2) === JSON.stringify(expected2),
// );
// // Output: Test 2: true

// // Test 3: Sort items with missing fields
// const items3 = [
//   { a: { id: 3 }, b: 1 },
//   { a: { id: 1 }, b: 2 },
//   { a: undefined, b: 3 },
// ];
// const sortOrder3 = '2|1|3';
// const expected3 = [
//   { a: { id: 1 }, b: 2 },
//   { a: { id: 3 }, b: 1 },
//   { a: undefined, b: 3 },
// ];

// const result3 = sortItemsByFieldName(items3, sortOrder3, 'a');
// console.log(
//   `Test 3:${JSON.stringify(result3)}`,
//   JSON.stringify(result3) === JSON.stringify(expected3),
// );

// Test 4: Sort items with missing fields
// const items4 = [
//   { a: undefined, b: 3 },
//   { a: { id: 50 }, b: 200 },
//   { a: { id: 522 }, b: 200 },
//   { a: { id: 3 }, b: 1 },
//   { a: { id: 33232 }, b: 200 },
//   { a: { id: 1 }, b: 2 },
// ];
// const sortOrder4 = '50|2|1|3';
// const expected4 = [
//   { a: { id: 50 }, b: 200 },
//   { a: { id: 1 }, b: 2 },
//   { a: { id: 3 }, b: 1 },
//   { a: undefined, b: 3 },
//   { a: { id: 522 }, b: 200 },
//   { a: { id: 33232 }, b: 200 },
// ];

// const result4 = sortItemsByFieldName(items4, sortOrder4, 'a');
// console.log(
//   `Test 4:${JSON.stringify(result4)}`,
//   JSON.stringify(result4) === JSON.stringify(expected4),
// );

// // handleing to pull the last sum values all the way back to the end of the range in case the dates are not there
// if (rowCulmutativeCalculationType && firstMonthKey) {
//   // checking if need to pull culmutative value all the way to the end
//   const { monthlyDates } = useZustandStore.getState();
//   const lastKey = monthlyDates[monthlyDates.length - 1] ?? '';
//   console.log('row', rows);

//   // if (lastKey !== isoDateKey) {
//   //   // means there are keys that are missed
//   //   const firstRow = rows[0];
//   //   const lastMonthValue = firstRow?.[quarterKey] || 0;
//   //   const lastQValue = firstRow?.[quarterKey] || 0;
//   //   const lastYValue = firstRow?.[totalYearKey] || 0;
//   //   monthlyDates.forEach((date: string) => {
//   //     if (entries[date] || date <= isoDateKey) return; // skipping visited dates
//   //     const {
//   //       isoDateKey: curMonthKey,
//   //       quarterKey: curQuarterKey,
//   //       totalYearKey: curYearKey,
//   //     } = getColumnKeysForDate(date);
//   //     rows.forEach((row: TableRow) => {
//   //       row[curMonthKey] = lastMonthValue;
//   //       row[curQuarterKey] = lastQValue;
//   //       row[curYearKey] = lastYValue;
//   //     });
//   //   });
//   // }
// }