import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow';
import OptionsMenu from '@/components/common/OptionsMenu/OptionsMenu';
import type { TableRow } from '@/components/table/shared/types';
import definitions from '@/components/table/shared/types';
import { generateTypeIconForTableRows } from '@/miscellaneous/helper/tableHelper';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import scssVariables from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/table.module.scss';
import flattenArray from '@/utils/helpers/flattenArray';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { getDepth, getSidebarCellContainerStyle, getSidebarCellContentStyle, getSideBarCellDivStyle, getSidebarDropdownStyle } from '../shared/TableHelpers/sidebarCellsStyles/sidebarCellsStylesHelper';
// eslint-disable-next-line import/no-cycle
import { ModelsTableContext } from '.';
import ModelsOptionsMenuPopup from './subComponent/ModelsOptionsMenuPopup';
import SidebarCellContent from './subComponent/SidebarCellContent';
import SidebarFormulaCell from './subComponent/SidebarFormulaCell';

/**
 * Renders a badge component with the provided badge value.
 *
 * @param {any} badge - The badge value to display.
 * @param {any} exception - The exception value to subtract from the badge value.
 * @returns {React.ReactElement} - A span element with the badge value and the 'sideBarBadge' class.
 */
const Badge = ({
  badge,
  backgroundColor,
  exception /** The exception value to subtract from the badge value. */
}: {
  badge: string;
  backgroundColor: string;
  exception?: number;
}): React.ReactElement | null => {
  const badgeCount = exception ? +badge - exception : badge;
  const badgeColorStyles = {
    backgroundColor,
    color: backgroundColor ? 'white' : 'inherit'
  };
  if (!badge || +badgeCount === 0) {
    return null;
  }
  return <span className={styles.sideBarBadge} style={badgeColorStyles} data-sentry-component="Badge" data-sentry-source-file="modelsSideBarCells.tsx">
      {badgeCount}
    </span>;
};
export interface ModelsSidebarCellProps {
  row: any;
  columnSpec: any;
  cell: any;
}

/**
 * Styled component that renders a div with a pseudo element before it to create a vertical bar.
 * The depth prop controls the width of the bar.
 */
const SideBarCellContainer = styled.div<{
  $depth: number;
}>`
  &::before {
    content: '';
    display: block;
    width: fit-content;
    height: 1px;
    background-color: white;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`;

/**
 * Renders sidebar cells for rows in the models table.
 *
 * Checks if the row has subrows and conditionally renders toggle buttons, actions buttons, and subrow content.
 * Handles renaming formulas, adding new rows, and generating icons for data types.
 *
 * Exported React component.
 */
const ModelsSidebarCell: React.FC<ModelsSidebarCellProps> = ({
  row,
  columnSpec,
  cell
}: ModelsSidebarCellProps) => {
  const buttonsRef = React.useRef(null);

  /**
   * Checks if the row has subrows.
   */
  const hasSubRows = row.subRows && row.subRows.length > 0 || row.original?.sidebar === 'Outputs' || row.original?.sidebar === 'Calculations' || row.original?.sidebar === 'Inputs';
  const curRowDepth = row?.original?.depth;

  /**
   * Conditionally sets the font style for the sidebar cell
   * based on the row depth and whether it has subrows.
   *
   * For depth 0, use black font.
   * For depth > 0 and hasSubRows, use bold font.
   * Otherwise no custom font style.
   */
  const sidebarCellFontStyle = curRowDepth === 0 ? styles.black : hasSubRows ? styles.bold : '';
  const sideBarCellDivStyle = getSideBarCellDivStyle(curRowDepth, styles);
  const sidebarDropdownStyle = getSidebarDropdownStyle(row, styles);
  const sidebarCellContentStyle = getSidebarCellContentStyle(columnSpec, hasSubRows, row.getIsExpanded(), curRowDepth, getDepth);
  const curFormula = row.original?.original_row_object;
  const sidebarCellContainerStyle = getSidebarCellContainerStyle(columnSpec, curRowDepth, getDepth, row.original.rowStyle?.backgroundColor);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const [newName, setNewName] = React.useState(cell.getValue());
  const {
    updateFormula
  } = useMutations();

  /**
   * Updates a formula with a new name by calling the updateFormula mutation.
   *
   * @param original - The original formula object
   * @param value - The new name for the formula
   */
  const renameFormula = (original: any, value: string) => {
    updateFormula.mutate({
      group: original?.group,
      input_type: original?.input_type,
      model_id: original?.model_id,
      branch_id: original?.branch_id,
      name: value,
      expression_string: original.expression_string,
      emoji: original?.emoji,
      id: original?.formula_id,
      output_id: original?.output_id,
      parent_id: original?.parent_id
    });
  };

  /**
   * Determines if the row is an output cell by checking if it has an output ID
   * and if its parent ID starts with "Outputs"
   */
  const isOutputCell = !!row.original.output_id && row.parentId?.split('-')[0] === 'Outputs';
  const {
    data,
    setData,
    setSelectedFormula
  }: any = useContext(ModelsTableContext);
  const calculationsRowData = data && flattenArray(data[1]?.subRows)?.slice(0, -1); // removing last item for add calc row, we are not pulling from useFormulas here to avoid rerender of the table.

  /**
   * Adds a new row to the data by filtering for an unused formula and pushing it to the subRows array.
   *
   * Finds the first unused formula from the activeModelFormulas that is not already in use
   * in any of the existing subRows. Creates a new object with the row data and
   * the unused formula data, sets the output_id to 'empty', and pushes it to the subRows array.
   * Calls setData to update the data state.
   */

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const addNewRow = (row: any) => {
    const filteredData = data[2].subRows.filter((subRow: any) => subRow.output_id); // all the rows that are in outputs

    /**
     * Filters the active model formulas to find an unused formula
     * that is not already in a row.
     *
     * Filters the formulas to only those in the 'Calculations' group.
     * Then filters out any formulas that have an output ID
     * or are already used in an existing row.
     *
     * Returns the first unused formula object.
     */
    const filteredFormulaRow = calculationsRowData.filter((formulaRow: TableRow) => formulaRow.group === 'Calculations' && !formulaRow.output_id).filter((formulaRow: TableRow) => !filteredData.find((calculationRow: any) => calculationRow.formula_id === formulaRow.formula_id))[0];
    if (!filteredFormulaRow) {
      return;
    }
    const newData = [...data];
    const newRow = {
      ...row,
      // ...filteredFormulaRow,
      name: filteredFormulaRow.name,
      group: filteredFormulaRow.group,
      input_type: filteredFormulaRow.input_type,
      emoji: filteredFormulaRow.emoji,
      formula_id: filteredFormulaRow.formula_id,
      id: `${filteredFormulaRow.formula_id}-Outputs`,
      output_id: 'empty',
      type: 'output'
    };
    setSelectedFormula(filteredFormulaRow);
    newData[2].subRows.splice(newData[2].subRows.length - 1, 0, newRow); // add the new row before the last row . last row should be the add button

    setData(newData);
  };
  const isAddBtn = row.original.type === 'button'; // check if the row is a button

  const badge = hasSubRows ? `${flattenArray(row.original.subRows).filter(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  (row: any) => row.type !== 'button').length}` : null;
  const {
    rowIDToScrollTo
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    rowIDToScrollTo: state.rowIDToScrollTo
  })));
  const [showRowBlinkOnRedirect, setShowBlinkingRow] = useState(false);
  useEffect(() => {
    if (rowIDToScrollTo === row.original.id) {
      setShowBlinkingRow(true);
    } else if (showRowBlinkOnRedirect) {
      const timer = setTimeout(() => {
        setShowBlinkingRow(false);
      }, 3000); // 3 seconds
      return () => clearTimeout(timer);
    }
  }, [rowIDToScrollTo]);

  /**
   * Computes the CSS classes for the sidebar cell container based on the current row and its state.
   *
   * The sidebar cell container classes are determined by the following conditions:
   * - The base `sideBarCellDivStyle` and `styles.sideBarCellChild` classes are always applied.
   * - If the current row's original row object ID matches the hash in the URL, the `styles.showFormulaRowLocation` class is applied to highlight the row.
   */
  const sidebarCellContainerClasses = classNames(`${sideBarCellDivStyle} ${styles.sideBarCellChild}`, {
    [(styles.showFormulaRowLocation as string)]: showRowBlinkOnRedirect
  });
  const isGroupRow = row.original.input_type === 'Group';

  /** Maps row types to their indicator colors */
  const indicatorColors = {
    Calculations: scssVariables.midBlue,
    Inputs: scssVariables.seafoam,
    Outputs: undefined
  };

  /** Generates left border indicator style based on row type, only for formula rows */
  const indicatorGenerator = (rowType: 'Calculations' | 'Inputs' | 'Outputs', type: 'section' | 'formula') => {
    if (type !== 'formula') return {};
    const borderColor = indicatorColors[rowType];
    return borderColor ? {
      borderLeft: `3px solid ${borderColor}`
    } : {};
  };

  /** Generates badge color based on row type */
  const badgeColorsGenerator = (rowType: 'Calculations' | 'Inputs' | 'Outputs') => {
    return indicatorColors[rowType];
  };

  /**
   * Renders sidebar cells for rows that are not output cells.
   *
   * Checks if the row has subrows and renders toggle and actions buttons.
   * Renders the model name, optionally allowing renaming.
   * Renders a hamburger menu for row options if not a total row or output.
   */
  return !isOutputCell ? <SideBarCellContainer $depth={row.depth} className={sidebarCellContainerClasses} style={{
    ...sidebarCellContainerStyle,
    ...indicatorGenerator(row.original.group, row.original.type)
  }} onClick={() => isGroupRow && row.toggleExpanded(!row.getIsExpanded())} data-sentry-element="SideBarCellContainer" data-sentry-component="ModelsSidebarCell" data-sentry-source-file="modelsSideBarCells.tsx">
      <Tooltip delayShow={1000} id="models-sidebar-cell-tooltip" noArrow data-sentry-element="Tooltip" data-sentry-source-file="modelsSideBarCells.tsx" />
      <div style={sidebarCellContentStyle} className={styles.subheader}>
        {hasSubRows && <button type="button" aria-label="Toggle Subrows" className={`${styles.toggleSubrowsButton} ${sidebarDropdownStyle}`} onClick={() => row.toggleExpanded(!row.getIsExpanded())}>
            <img src={definitions.images.down} width={50} height={50} alt="" />
          </button>}
        <div className={`${sidebarCellFontStyle} ${styles.departmentName}`} style={{
        fontWeight: row.original.rowStyle?.fontWeight
      }}>
          <div>
            {!isOutputCell && !isAddBtn && generateTypeIconForTableRows(row.original.input_type, !row.getIsExpanded())}
          </div>
          <div className="flex w-full items-center text-left">
            <SidebarCellContent row={row} isRenaming={isRenaming} isAddBtn={isAddBtn} newName={newName} setIsRenaming={setIsRenaming} setNewName={setNewName} renameFormula={renameFormula} addNewRow={addNewRow} columnSpec={columnSpec} data-sentry-element="SidebarCellContent" data-sentry-source-file="modelsSideBarCells.tsx" />
            <Badge badge={(badge as string)} backgroundColor={(badgeColorsGenerator(row.original.group) as string)} data-sentry-element="Badge" data-sentry-source-file="modelsSideBarCells.tsx" />
          </div>
        </div>
      </div>

      {row.depth !== 0 && !isAddBtn && <span ref={buttonsRef} className={`${styles.actions}`} style={{
      paddingTop: hasSubRows && row.getIsExpanded() && row.depth === 0 ? 24 : 0,
      paddingBottom: hasSubRows && row.getIsExpanded() && row.depth === 0 ? 12 : 0
    }}>
          <ModelsOptionsMenuPopup row={row} buttonsRef={buttonsRef}>
            <OptionsMenu items={columnSpec?.getOptions(row, curFormula?.model_id, setIsRenaming)?.splice(1)} className={styles.formulaInputOptions} />
          </ModelsOptionsMenuPopup>
        </span>}
    </SideBarCellContainer> : <SidebarFormulaCell row={row} data={data && data.length > 1 ? data[2].subRows : []} formulaRows={data} setFormulaRows={setData} calculationsRowsData={calculationsRowData} // removing last item
  hasSubRows={hasSubRows} setSelectedFormula={setSelectedFormula} data-sentry-element="SidebarFormulaCell" data-sentry-component="ModelsSidebarCell" data-sentry-source-file="modelsSideBarCells.tsx" />;
};
export default ModelsSidebarCell;