/* eslint-disable unused-imports/no-unused-vars */
// ignore unused variables eslint or impoorts
import { createContext, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import TableLoader from '@/components/common/Loaders/tableLoader';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { generateDateColumnsFromList } from '@/utils/dateUtils';
import useMutations from '@/utils/hooks/mutations/useMutations';

// eslint-disable-next-line import/no-cycle
import Table from '../shared/table/table';
import type { TableRow } from '../shared/types';
// eslint-disable-next-line import/no-cycle
import FinancialsColumnGenerator from './columnGenerator';
import financialSharedColumnSettings from './financialSharedColumnSetting';
import processedData from './processedData';
/**
 * Represents a table component for managing hiring plans.
 * Retrieves employee data and renders a table with relevant columns and settings.
 * @returns {JSX.Element} - Returns a JSX element representing the hiring plan table.
 */

export const FinancialsTableContext = createContext({});
const FinancialsTable = () => {
  const {
    createOutput,
    updateOutput,
    updateReportItem,
    deleteOutput,
    deleteReportItem
  } = useMutations();
  const {
    branchOutputs,
    accountTrees
  } = DataStore(useShallow((state: DataStoreState) => ({
    branchOutputs: state.branchOutputs,
    accountTrees: state.accountTrees
  })));
  const [newRowIndex, setNewRowIndex] = useState(0);
  const {
    activeReport,
    activeReportItems,
    activeBranchId,
    monthlyDates,
    currentDate
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeReport: state.activeReport,
    activeReportItems: state.activeReportItems,
    activeBranchId: state.activeBranch?.id ?? null,
    monthlyDates: state.monthlyDates,
    currentDate: state.currentDate
  })));
  const {
    bothDataMap,
    HPformulaEntries
  } = CalculationsStore(useShallow((state: CalculationsStoreState) => ({
    bothDataMap: state.bothDataMap,
    HPformulaEntries: state.HPformulaEntries
  })));
  const [dates, setDates] = useState<string[]>(monthlyDates);
  const [columns, setColumns] = useState<any>([]);
  const [data, setData] = useState<TableRow[]>([]);
  useEffect(() => {
    // handle the dates
    if (!activeReport?.settings) return;
    const result = generateDateColumnsFromList(monthlyDates, activeReport.settings);
    setDates(result);
  }, [monthlyDates, activeReport]);
  useEffect(() => {
    if (!activeReport?.id || activeReportItems?.length === 0) return;
    const newData = processedData(activeReport ?? null, activeReportItems, accountTrees ?? []);
    // deep clone the list newData
    const deepClone = [...newData];
    setData(deepClone);
  }, [accountTrees, activeReport, activeReportItems, branchOutputs, dates, activeBranchId, bothDataMap, HPformulaEntries, currentDate // we need this to re-calc data if current date changes
  ]);
  useEffect(() => {
    // columns update
    if (activeReportItems?.length === 0) return;
    const newColumns = FinancialsColumnGenerator({
      createOutput,
      updateOutput,
      updateReportItem,
      deleteOutput,
      deleteReportItem,
      setNewRowIndex
    }, activeBranchId, dates, activeReport);
    setColumns(newColumns);
  }, [accountTrees, activeBranchId, dates, activeReport]); // adding t here crashed the tests

  const memoizedValue = useMemo(() => ({
    newRowIndex,
    setNewRowIndex
  }), [newRowIndex]);
  const memoizedTable = useMemo(() => {
    const res = <FinancialsTableContext.Provider value={memoizedValue}>
        <Table data={data} columns={columns} settings={financialSharedColumnSettings} headerTitle={activeReport?.name ?? 'Financials Table'} hideCogIcon enableDnd />
      </FinancialsTableContext.Provider>;
    return res;
  }, [data, columns]);
  if (activeReportItems?.length > 0 && data?.length > 0) {
    return memoizedTable;
  }
  return <TableLoader data-sentry-element="TableLoader" data-sentry-component="FinancialsTable" data-sentry-source-file="index.tsx" />;
};
export default FinancialsTable;