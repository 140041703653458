import { useRouter } from 'next/router';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import AIView from '@/components/AIComponents/AIView/AIView';
import TemplateView from '@/components/AIComponents/TemplateView/TemplateView';
import OnboardingPopup from '@/components/common/CompanyOnboardPopup/OnboardingPopup';
import ConfettiAnimation from '@/components/common/ConfettiAnimation/ConfettiAnimation';
import ModelsDateRangePicker from '@/components/common/Header/components/ModelsDateRangePicker/ModelsDateRangePicker';
import HelpButton from '@/components/common/HelpButton/HelpButton';
import SwoopAnimation from '@/components/common/SwoopAnimation/SwoopAnimation';
import TutorialsModal from '@/components/TutorialsModal/TutorialsModal';
import usePdfStore from '@/miscellaneous/pdf-store/pdf_store';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import usePrepareCompanyForScraping from '@/utils/hooks/CompanyScraping/usePrepareCompanyForScraping';
import type { Models } from '@/utils/hooks/models/useModels';
import type { Report } from '@/utils/hooks/reports/reportTypes';
import { useRunwayCalculations } from '@/utils/hooks/useRunwayCalculations';
import type { HiringPlan } from '@/utils/types/HiringTypes';
import dynamicRoute from '../../constant';
import AppLoader from '../AppLoader/AppLoader';
import styles from './pageLayout.module.scss';
const IntercomComponent = lazy(() => import('@/components/common/IntercomComponent/IntercomComponent'));
const Header = lazy(() => import('@/components/common/Header'));
const ModelsSubHeader = lazy(() => import('@/components/models/ModelsSubHeader/ModelsSubHeader'));
const Dashboard = lazy(() => import('@/components/Dashboard/Dashboard'));
const BasicDashboard = lazy(() => import('@/components/Dashboard/financials/FinancialsDashboard/BasicDashboard-old'));
const FinancialReportSubHeader = lazy(() => import('@/components/financialReports/FinancialReportSubHeader/FinancialReportSubHeader'));
const HiringPlanSubHeader = lazy(() => import('@/components/HiringPlan/HiringPlanSubHeader/HiringPlanSubHeader'));
const HiringPlanDashboard = lazy(() => import('@/components/HiringPlan/HiringPlanDashboard'));
const WIDGET_MAPPINGS = ({
  financials: (['elfsight-app-2a7024e8-db5c-409b-9579-8189c7c184f3'] as string[]),
  hiringPlan: (['elfsight-app-7c489467-f3e7-497e-b2d2-15d2f8c3a3ce', 'elfsight-app-90d43d60-2b21-44c1-80c6-a9c15752d20f'] as string[]),
  models: (['elfsight-app-fe462b14-056c-4431-b43a-fa57dacb4881'] as string[]),
  assumptions: null
} as const);
const subHeaderComponents = {
  financials: <FinancialReportSubHeader />,
  hiringPlan: <HiringPlanSubHeader />,
  models: <ModelsSubHeader isSettingsAndShareActive />,
  assumptions: <ModelsSubHeader isLinksHidden />,
  '': <div />
};

/**
 * Extracts the dynamic route key from the given pathname.
 *
 * @param {string} pathname - The current page pathname.
 * @returns {string} The dynamic route key, or an empty string if no match is found.
 */
const getDynamicRouteKey = (pathname: string) => {
  if (pathname.includes(dynamicRoute.financials)) return 'financials';
  if (pathname.includes(dynamicRoute.hiringPlan)) return 'hiringPlan';
  if (pathname.includes(dynamicRoute.models)) return 'models';
  if (pathname.includes(dynamicRoute.assumptions)) return 'assumptions';
  return '';
};
const getDynamicTitleKey = (pathname: string, activeReport: Report, activeModel: Models, activeHiringPlan: HiringPlan, translate: (key: string) => string) => {
  if (pathname.includes(dynamicRoute.financials)) return activeReport?.name || translate('financials');
  if (pathname.includes(dynamicRoute.hiringPlan)) return activeHiringPlan?.name || translate('hiringPlan');
  if (pathname.includes(dynamicRoute.models)) return activeModel?.name || translate('models');
  if (pathname.includes(dynamicRoute.assumptions)) return translate('assumptions');
  return '';
};
const subHeaderGenerator = (pathname: string) => {
  const key = getDynamicRouteKey(pathname);
  return subHeaderComponents[key] || <div />;
};
const titleGenerator = (pathname: string, translate: (key: string) => string, activeReport: Report, activeModel: Models, activeHiringPlan: HiringPlan) => {
  const title = getDynamicTitleKey(pathname, activeReport, activeModel, activeHiringPlan, translate);
  return title;
};
const dashboardGenerator = (pathname: string, financialsAnimated: boolean, setFinancialsAnimated: (animated: boolean) => void, hiringplanAnimated: boolean, setHiringPlanAnimated: (animated: boolean) => void, modelsAnimated: boolean, setModelsAnimated: (animated: boolean) => void, assumptionsAnimated: boolean, setAssumptionsAnimated: (animated: boolean) => void) => {
  if (pathname.includes(dynamicRoute.financials)) return <SwoopAnimation run={financialsAnimated} onSwoopEnd={() => {
    setFinancialsAnimated(false);
  }} direction="right">
        <BasicDashboard financialsAnimated={financialsAnimated} />
        <Dashboard />
      </SwoopAnimation>;
  if (pathname.includes(dynamicRoute.hiringPlan)) {
    return <SwoopAnimation run={hiringplanAnimated} onSwoopEnd={() => {
      setHiringPlanAnimated(false);
    }} direction="right">
        <HiringPlanDashboard hiringplanAnimated={hiringplanAnimated} />
      </SwoopAnimation>;
  }
  if (pathname.includes(dynamicRoute.models)) return <Dashboard renderGraphPlaceholder animated={modelsAnimated} setAnimated={setModelsAnimated} />;
  if (pathname.includes(dynamicRoute.assumptions)) return <SwoopAnimation run={assumptionsAnimated} onSwoopEnd={() => {
    setAssumptionsAnimated(false);
  }} direction="right">
        <BasicDashboard financialsAnimated={assumptionsAnimated} />
        <Dashboard />
      </SwoopAnimation>;
  return <Dashboard data-sentry-element="Dashboard" data-sentry-component="dashboardGenerator" data-sentry-source-file="PageLayout.tsx" />;
};

/**
 * Renders the page sub-header, including the page title and any relevant sub-header components.
 *
 * @param {Object} props - The component props.
 * @param {string | undefined} props.path - The current page path.
 * @param {(key: string) => string} props.translate - A function to translate strings.
 * @returns {JSX.Element} The rendered page sub-header.
 */
const PageSubHeader = ({
  path,
  translate
}: {
  path: string | undefined;
  translate: (key: string) => string;
}) => {
  const isModels = path?.includes(dynamicRoute.models);
  const {
    activeReport,
    activeModel,
    activeHiringPlan
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeReport: state.activeReport,
    activeModel: state.activeModel,
    activeHiringPlan: state.activeHiringPlan
  })));
  const title = titleGenerator(path || '', translate, activeReport, activeModel, activeHiringPlan);
  const widgets = path ? WIDGET_MAPPINGS[(getDynamicRouteKey(path) as keyof typeof WIDGET_MAPPINGS)] : null;
  return <div className="w-full" data-sentry-component="PageSubHeader" data-sentry-source-file="PageLayout.tsx">
      <div className={styles.pageTitleContainer}>
        <div className="flex items-center gap-2">
          <h1 className={styles.pageTitle}>{title}</h1>

          {widgets && <HelpButton widgets={widgets} />}
        </div>

        <span>{isModels && <ModelsDateRangePicker />}</span>
      </div>

      {subHeaderGenerator(path || '')}
    </div>;
};

/**
 * Renders the main page layout, including the header, page sub-header, and child content.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child content to be rendered within the layout.
 * @returns {JSX.Element} The rendered page layout.
 */
const PageLayout = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    t: translate
  } = useTranslation('pages');
  const {
    pathname
  } = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    financialsAnimated,
    setFinancialsAnimated,
    hiringplanAnimated,
    setHiringPlanAnimated,
    modelsAnimated,
    setModelsAnimated,
    assumptionsAnimated,
    setAssumptionsAnimated,
    isConfettiAnimation
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    financialsAnimated: state.financialsAnimated,
    setFinancialsAnimated: state.setFinancialsAnimated,
    hiringplanAnimated: state.hiringplanAnimated,
    setHiringPlanAnimated: state.setHiringPlanAnimated,
    modelsAnimated: state.modelsAnimated,
    setModelsAnimated: state.setModelsAnimated,
    assumptionsAnimated: state.assumptionsAnimated,
    setAssumptionsAnimated: state.setAssumptionsAnimated,
    isConfettiAnimation: state.isConfettiAnimation
  })));
  useRunwayCalculations();
  const {
    setDashboardRef
  } = usePdfStore(useShallow(state => state));
  const dashboardRef = useRef<HTMLDivElement>(null);
  usePrepareCompanyForScraping(setModalOpen);
  useEffect(() => {
    if (dashboardRef.current) {
      setDashboardRef(dashboardRef.current);
    }
  }, [pathname, dashboardRef.current]);
  return <Suspense fallback={<AppLoader />} data-sentry-element="Suspense" data-sentry-component="PageLayout" data-sentry-source-file="PageLayout.tsx">
      {isConfettiAnimation && <ConfettiAnimation particleCount={300} duration={2900} />}
      <Header data-sentry-element="Header" data-sentry-source-file="PageLayout.tsx" />

      {!pathname.includes(dynamicRoute.setting) && <>
          <PageSubHeader path={pathname} translate={translate} />
          <div className={styles.aiContainer}>
            <TemplateView />
            <AIView />
          </div>

          <div ref={dashboardRef}>
            {dashboardGenerator(pathname, financialsAnimated, setFinancialsAnimated, hiringplanAnimated, setHiringPlanAnimated, modelsAnimated, setModelsAnimated, assumptionsAnimated, setAssumptionsAnimated)}
          </div>
        </>}
      <TutorialsModal data-sentry-element="TutorialsModal" data-sentry-source-file="PageLayout.tsx" />
      {children}
      <IntercomComponent data-sentry-element="IntercomComponent" data-sentry-source-file="PageLayout.tsx" />
      {modalOpen && <OnboardingPopup open={modalOpen} setOpen={setModalOpen} />}
    </Suspense>;
};
export default PageLayout;