import Cleave from 'cleave.js/react';
import numeral from 'numeral';
import type { FC } from 'react';
import { useState } from 'react';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
interface HandlerFunctions {
  onBlur?: (cell: any, row: any) => (event: {
    target: {
      value: string | null;
    };
  }) => void;
  onFocus?: (cell: any, row: any) => (event: {
    target: {
      value: string | null;
    };
  }) => void;
  onChange?: (cell: any, row: any) => (event: {
    target: {
      value: string | null;
    };
  }) => void;
}
interface ColumnSpec {
  handlerFunctions?: HandlerFunctions;
  accessorKey?: string;
}
interface CurrencyCellProps {
  cell: any;
  row?: any;
  columnSpec: ColumnSpec;
  style?: any;
  defaultValue: string;
  symbol?: string;
  symbolPosition?: 'left' | 'right';
  focus?: boolean;
  className?: string;
  allowedDecimals?: number;
}
const CurrencyCell: FC<CurrencyCellProps> = ({
  cell,
  row,
  columnSpec,
  style,
  defaultValue,
  symbol = '$',
  symbolPosition = 'left',
  focus = false,
  className,
  allowedDecimals = 3
}) => {
  const [value, setValue] = useState(() => {
    return Number.isNaN(+defaultValue) || defaultValue === null || defaultValue === '' ? 0 : (numeral(defaultValue).value() as number);
  });

  // Updates the value when the input changes.
  const handleInputChange = (inputValue: string) => {
    setValue((numeral(inputValue).value() as number));
  };

  // Extracts the handler functions from the column specification.
  const actualHandlerFunctions = columnSpec.handlerFunctions && Object.keys(columnSpec.handlerFunctions).reduce((acc: any, key: any) => {
    // @ts-ignore
    acc[key] = columnSpec.handlerFunctions[key](cell, row);
    return acc;
  }, {});

  // Extracts the symbol and percentage information from the symbol prop.
  const isPercentage = symbol === '%';

  // Determines if the symbol should be on the right side of the input.
  const isRightSymbol = symbolPosition === 'right';

  // Determines if the symbol should be shown.
  const showSymbol = value !== null && !Number.isNaN(value);

  // Common props for the input element.
  const commonProps = {
    value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value),
    className: className || styles.baseCell,
    autoFocus: focus,
    ...actualHandlerFunctions
  };

  // Options for the Cleave.js library.
  const cleaveOptions = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalScale: allowedDecimals,
    ...(isRightSymbol ? {} : {
      prefix: symbol
    })
  };

  // Updates the value to be a percentage if the input is a percentage and the input loses focus.
  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isPercentage) {
      actualHandlerFunctions.onBlur({
        target: {
          value: (numeral(e.target.value).value() as number) / 100
        }
      });
    } else {
      actualHandlerFunctions.onBlur({
        target: {
          value: (numeral(e.target.value).value() as number)
        }
      });
    }
  };

  /**
   * Handles the onKeyDown event for the currency input field. If the user presses the Enter key, the input field will lose focus.
   *
   * @param e - The React.KeyboardEvent object representing the key press event.
   */
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };
  return <div className={isRightSymbol ? styles.currencyCellRight : undefined} data-sentry-component="CurrencyCell" data-sentry-source-file="currencyCell.tsx">
      <Cleave style={{
      ...style,
      ...(isRightSymbol && {
        paddingRight: showSymbol ? '35px' : '24px',
        backgroundColor: 'transparent !important'
      })
    }} placeholder={`0.00${symbol}`} options={cleaveOptions} {...commonProps} onBlur={onBlurHandler} onKeyDown={onKeyDownHandler} onFocus={e => e.target.select()} data-sentry-element="Cleave" data-sentry-source-file="currencyCell.tsx" />
      {isRightSymbol && showSymbol && <span>{symbol}</span>}
    </div>;
};
export default CurrencyCell;