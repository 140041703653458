import { Search, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import DeletePopup from '@/components/common/DeletePopup/DeletePopup';
import type { ITemplate } from '@/components/Settings/Template/components';
import MostUsedTemplates from '@/components/Settings/Template/components/MostUsedTemplates';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { useAllTemplatesQuery } from '@/utils/hooks/template/useTemplateHooks';
import type { TemplateGeneration } from '../../onboardingPopupTypes';
import styles from './templateGenerationStage.module.scss';

/**
 * Renders the TemplateGenerationStage component, which allows the user to manage templates for a company onboarding process.
 *
 * This component fetches and assigns recommended templates based on the company's details and goals, and provides functionality to add, remove, and search for templates.
 *
 * @param {Object} data - The TemplateGeneration data object, containing the list of chosen templates.
 * @param {function} onChange - A function to update the TemplateGeneration data object.
 * @returns {JSX.Element} - The rendered TemplateGenerationStage component.
 */
const TemplateGenerationStage = ({
  data,
  onChange
}: {
  data: TemplateGeneration;
  onChange: (data: TemplateGeneration) => void;
}) => {
  const {
    allTemplates
  } = useAllTemplatesQuery();
  const {
    t: translate
  } = useTranslation(['template', 'company']);
  const {
    chosenTemplates
  } = data || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    templateAssigner
  } = useMutations();
  /**
   * Initializes the `templateLibrary` state with the templates that are not currently active.
   *
   * This effect is used to populate the list of available templates that can be added to the active templates.
   * It filters the `allTemplates` array to exclude any templates that are already in the `chosenTemplates` array.
   */
  const [templateLibrary, setTemplateLibrary] = useState<ITemplate[]>(allTemplates?.filter((t: ITemplate) => !chosenTemplates?.includes(t)) || []);
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));

  /**
   * Fetches and assigns recommended templates based on the company's details and goals.
   *
   * This function is called when the `chosenTemplates` array is empty, or when the `chosenTemplates` array changes, to populate the list of available templates.
   * It uses the `templateAssigner` mutation to fetch recommended templates based on the company's details and goals, and then updates the `chosenTemplates` array with the recommended templates.
   *
   * @param {Object} originalState - The original CompanyState object, containing details about the company.
   * @returns {Promise<void>} - A Promise that resolves when the recommended templates have been fetched and assigned.
   */
  const getRecommendedTemplates = async () => {
    setAppLoader(true);
    // Call the templateAssigner mutation with the templateAssignerParams
    templateAssigner?.mutate({}, {
      onSuccess: (response: any) => {
        setAppLoader(false);
        // Update the chosenTemplates array with the recommended templates
        onChange({
          chosenTemplates: [...allTemplates.filter((temp: ITemplate) => response.templates.some((templateId: number) => temp.id === +templateId))]
        });
      },
      onError: (error: any) => {
        setAppLoader(false);
        console.error('Error fetching recommended templates:', error);
      }
    });
  };

  /**
   * Fetches and assigns recommended templates based on the company's details and goals.
   *
   * This effect is called when the `allTemplates` array is available and not empty. It calls the `getRecommendedTemplates` function to fetch and assign the recommended templates based on the company's details and goals.
   */
  useEffect(() => {
    if (allTemplates && allTemplates?.length > 0 && chosenTemplates?.length === 0) {
      getRecommendedTemplates();
    }
  }, [allTemplates]);

  // Filter templates when chosenTemplates changes
  useEffect(() => {
    setTemplateLibrary(allTemplates?.filter((t: ITemplate) => !chosenTemplates?.includes(t)));
  }, [chosenTemplates]);

  // Using for to keep track of the deleted template and pass to the remove template popup
  const [deletedTemplate, setDeletedTemplate] = useState<ITemplate | null>(null);
  const [searchValue, setSearchValue] = useState('');

  /**
   * Handles the selection of a template to be added to the active templates.
   *
   * This function is called when a user selects a template from the available templates list.
   * It adds the selected template to the `chosenTemplates` array and updates the `templateLibrary`
   * array to exclude the newly added template.
   *
   * @param {ITemplate} template - The template that was selected by the user.
   * @returns {void}
   */
  // Modify onSelectTemplate to replace instead of add
  const onSelectTemplate = (template: ITemplate) => {
    // Replace the entire array with just the new template
    const newTemplates = [template];
    onChange({
      chosenTemplates: newTemplates
    });
    setTemplateLibrary(allTemplates.filter((t: ITemplate) => !newTemplates?.includes(t)));
  };

  // Handles the removal of a template from the active templates
  const handleRemoveTemplate = (template: ITemplate) => {
    setDeletedTemplate(template);
    setIsModalOpen(true);
  };

  // Removes the deleted template from the active templates and the allTemplatesState
  const onRemoveFromActiveTemplates = () => {
    const filteredTemplates = chosenTemplates.filter((t: ITemplate) => t.id !== deletedTemplate?.id);
    onChange({
      chosenTemplates: filteredTemplates
    });
    setTemplateLibrary(allTemplates?.filter((t: ITemplate) => !filteredTemplates?.includes(t)));
    setIsModalOpen(false);
  };

  /**
   * Initializes the `templateLibrary` state with the templates that are not currently active.
   *
   * This effect is used to populate the list of available templates that can be added to the active templates.
   * It filters the `allTemplates` array to exclude any templates that are already in the `chosenTemplates` array.
   *
   * @param {ITemplate[]} allTemplates - The full list of available templates.
   * @param {ITemplate[]} chosenTemplates - The list of currently active templates.
   */
  useEffect(() => {
    if (allTemplates && allTemplates?.length > 0) {
      setTemplateLibrary(allTemplates?.filter((t: ITemplate) => !chosenTemplates?.includes(t)));
    }
  }, [allTemplates]);

  /**
   * Handles the search functionality for the template library.
   *
   * This function updates the `searchValue` state with the provided value, and then filters the `allTemplates` array to only include templates that are not already in the `chosenTemplates` array and have a name that includes the search value (case-insensitive). The filtered templates are then set as the `templateLibrary` state.
   *
   * @param {string} value - The search value entered by the user.
   */
  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredTemplates = allTemplates?.filter((t: ITemplate) => !chosenTemplates?.includes(t));
    setTemplateLibrary(filteredTemplates.filter((t: ITemplate) => t.name.toLowerCase()?.includes(value.toLowerCase())));
  };
  return <div className="mb-12 h-full w-full overflow-y-auto p-4" data-testid="your-custom-plan" data-sentry-component="TemplateGenerationStage" data-sentry-source-file="TemplateGenerationStage.tsx">
      <div className="mb-3">
        <div className={styles.templateLibraryHeader}>
          <h1>{translate('company:your_custom_plan')}</h1>
        </div>
        {chosenTemplates?.length > 0 ? <MostUsedTemplates templates={chosenTemplates} t={translate} handleSelectTemplate={handleRemoveTemplate} title={null} buttonText={translate('company:remove_template')} /> : <p className={styles.noTemplates}>
            {translate('company:no_active_custom_plan')}
          </p>}
      </div>

      <div className="mb-3">
        <div className={styles.templateLibraryHeader}>
          <h1>{translate('company:template_library')}</h1>

          <div className={styles.searchTemplatesInput}>
            <Search className={styles.searchIcon} size={16} data-sentry-element="Search" data-sentry-source-file="TemplateGenerationStage.tsx" />
            <input type="text" value={searchValue} placeholder={translate('company:searchPlaceholder')} onChange={e => handleSearch(e.target.value)} data-testid="search-input"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus />
            {searchValue && <X className={styles.clearIcon} size={16} onClick={() => setSearchValue('')} data-testid="clear-icon" />}
          </div>
        </div>
        <MostUsedTemplates templates={templateLibrary} t={translate} handleSelectTemplate={onSelectTemplate} title={null} buttonText={translate('company:add_template')} disabledSelect={chosenTemplates?.length > 0} disableText="You can only select one template" data-sentry-element="MostUsedTemplates" data-sentry-source-file="TemplateGenerationStage.tsx" />
      </div>

      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={`${translate('company:remove_item_confirmation')} ${deletedTemplate?.name} template?`} sumbitTitle={translate('company:remove_template')} closeModal={() => setIsModalOpen(false)} onDelete={onRemoveFromActiveTemplates} isLastData={false} data-sentry-element="DeletePopup" data-sentry-source-file="TemplateGenerationStage.tsx" />
    </div>;
};
export default TemplateGenerationStage;