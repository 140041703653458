/**
 * Function to retrieve unique departments from the employee data.
 * @param data - The array of employee data.
 * @returns Array of unique department names.
 */
// Write a function that pulls prompts from zustand, and filters them by prompt.type = 'hiring-plan'

import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { OutputValues } from '@/miscellaneous/store/calulcationsStoreHelper';
import type { AccountTree } from '@/utils/hooks/Outputs/useOutputs';
import { type Report, type ReportItem } from '@/utils/hooks/reports/reportTypes';
import { groupItemsByParent } from './financialTableUtils';
import { renderRows } from './renderRows';

/**
 * Function to format the api data to hiring table's specifications.
 * @param branchEmployees
 * @returns Array of row element data
 */
const processedData = (report: Report | null, reportItems: ReportItem[], accountTrees
// accountValuesMap: AccountValuesMap,
: AccountTree[]) => {
  if (!reportItems || !report) return [{
    sidebar: '',
    id: `emptySidebar`,
    subRows: []
  }];
  const {
    getAccountValuesByDateRange
  } = CalculationsStore.getState();
  const getAccountValuesMapByID = (outputID: number): OutputValues | undefined => getAccountValuesByDateRange({
    outputID
  });
  const items = groupItemsByParent(reportItems, report.sort_order ?? ''); // grouping report items by parent
  const result = renderRows({
    report,
    items,
    parentSection: undefined,
    // its undefined since its first level report
    accountTrees,
    getAccountValuesMapByID,
    depth: 0
  });
  return result;
};
export default processedData;