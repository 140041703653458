/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import 'regenerator-runtime/runtime';

import { useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';

// Extend the Window interface to include the SpeechRecognition API
declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

// An array of trigger phrases that the speech recognition listens for
const triggerPhrases: string[] = [
  'hi lucid',
  'hey lucid',
  'hello lucid',
  'hi lucy',
  'hey lucy',
  'hello lucy',
  'hi luca',
  'hey luca',
  'hello luca',
  'hi lusi',
  'hey lusi',
  'hello lusi',
  'hi lusiid',
  'hey lusiid',
  'hello lusiid',
  'hi lucit',
  'hey lucit',
  'hello lucit',
];

/**
 * A React hook that listens for voice commands starting with "hi lucid", "hey lucid", "hello lucid", and similar variations, and triggers a state update when a matching command is detected.
 *
 * The hook uses the browser's built-in speech recognition API to continuously listen for the trigger phrases. When a match is found, the `setHotWordTriggered` function from the `useUtilsStore` is called to update the application state.
 *
 * The hook starts and stops the speech recognition based on the `hotWordListeningMode` state from the `useUtilsStore`. When `hotWordListeningMode` is false, the speech recognition is stopped.
 */
const useHotWordListener = (isHotWordEnabled: boolean = true) => {
  const recognitionRef = useRef<any>(null);
  const isListeningRef = useRef(false);

  // Get the `setHotWordTriggered` and `hotWordListeningMode` functions from the `useUtilsStore`
  const { setHotWordTriggered, hotWordListeningMode, setAddVoiceResponse } =
    useUtilsStore(
      useShallow((state) => ({
        hotWordTriggered: state.hotWordTriggered,
        setHotWordTriggered: state.setHotWordTriggered,
        hotWordListeningMode: state.hotWordListeningMode,
        setAddVoiceResponse: state.setAddVoiceResponse,
      })),
    );

  //  The `useEffect` hook listens for changes in the `hotWordListeningMode` state and starts or stops the speech recognition accordingly.
  useEffect(() => {
    if (!isHotWordEnabled) return;
    if (!hotWordListeningMode) {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
      isListeningRef.current = false;
      return;
    }

    // Check if the browser supports speech recognition
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    // If the browser does not support speech recognition, log a message and return
    if (!SpeechRecognition) {
      console.log('Speech recognition not supported in this browser');
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    const recognition = recognitionRef.current;

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    // Function to start the speech recognition
    const startListening = () => {
      if (!isListeningRef.current && recognitionRef.current) {
        try {
          recognition.start();
          isListeningRef.current = true;
        } catch (error) {
          console.log('Recognition is already started');
        }
      }
    };

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript.toLowerCase().trim();

      // Check if the transcript contains any of the trigger phrases
      if (triggerPhrases.some((phrase) => transcript.includes(phrase))) {
        setHotWordTriggered(true);
        setAddVoiceResponse(true);
      }

      isListeningRef.current = false;

      // Restart the speech recognition after a delay
      setTimeout(startListening, 1000);
    };

    // When the speech recognition ends, set the `isListening` flag to false and restart the speech recognition after a delay
    recognition.onend = () => {
      isListeningRef.current = false;
      setTimeout(startListening, 1000);
    };

    startListening();

    return () => {
      // Stop the speech recognition when the component unmounts
      if (recognitionRef.current) {
        recognition.stop();
        recognitionRef.current = null;
      }
      isListeningRef.current = false;
    };
  }, [setHotWordTriggered, hotWordListeningMode, isHotWordEnabled]);
};

export default useHotWordListener;
