import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import styles from '@/styles/modal.module.scss';
interface IDeletePopup {
  title?: string;
  sumbitTitle?: string;
  closeModal: () => void;
  onDelete?: () => void;
  isModalOpen: boolean | undefined;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void | undefined;
  setCurrentData?: React.Dispatch<any>;
  isLastData?: boolean;
  confirmButtonClass?: string;
  cancelButtonClass?: string;
}

/**
 * Styles for the popup content.
 * Sets width, height, padding, box shadow, background color,
 * border radius, and backdrop filter.
 */
export const popupContentStyle = {
  width: 'auto',
  height: 'auto',
  padding: '0px',
  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  borderRadius: '16px',
  backdropFilter: 'blur(25px)'
};
function DeletePopup({
  title,
  sumbitTitle,
  closeModal,
  onDelete,
  isModalOpen,
  setIsModalOpen,
  setCurrentData,
  isLastData,
  confirmButtonClass,
  cancelButtonClass
}: IDeletePopup) {
  const {
    t
  } = useTranslation(['common']);
  return <Popup open={isModalOpen} contentStyle={popupContentStyle} onClose={() => {
    setIsModalOpen(false);
    setCurrentData?.(null);
  }} modal nested closeOnDocumentClick={false} data-sentry-element="Popup" data-sentry-component="DeletePopup" data-sentry-source-file="DeletePopup.tsx">
      <div className={styles.dangerZoneModal} data-testid="info-modal">
        <div className={styles.dangerZoneModalContent}>{title}</div>
        <div className="flex items-center justify-between">
          <Button onPress={closeModal} type="button" className={classNames(styles.modalCancelBtn, cancelButtonClass)} data-sentry-element="Button" data-sentry-source-file="DeletePopup.tsx">
            {t('common:cancel')}
          </Button>
          <Button data-testid="confirm-button" onPress={onDelete} className={classNames(styles.dangerZoneBtn, confirmButtonClass)} isDisabled={isLastData} data-sentry-element="Button" data-sentry-source-file="DeletePopup.tsx">
            {sumbitTitle}
          </Button>
        </div>
      </div>
    </Popup>;
}
export default DeletePopup;