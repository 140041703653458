import Fuse from 'fuse.js';

/** * FuzzySearch<T> * * Performs a fuzzy search on an array of objects and returns a sorted list of matching items. * * @template T - The type of objects in the data array. * * @param {T[]} data - The array of objects to search through. * @param {keyof T} key - The key within the objects to perform the search on. This key should map to a string value in the object. * @param {string[]} keywords - An array of strings containing the keywords to search for. The function will return objects whose specified key value closely matches any of these keywords. * * @returns {T[]} - An array of objects from the input data that match the search keywords. The results are sorted in ascending order based on their match score (closest matches first). * * @example * // Example usage: * const data = [ * { id: 1, name: ‘Apple’ }, * { id: 2, name: ‘Banana’ }, * { id: 3, name: ‘Orange’ }, * { id: 4, name: ‘Grape’ }, * { id: 5, name: ‘Pineapple’ }, * ]; * * const keywords = [‘apl’, ‘gra’]; * * const results = FuzzySearch(data, ‘name’, keywords); * * console.log(results); * // Output: [ * // { id: 1, name: ‘Apple’ }, * // { id: 4, name: ‘Grape’ } * // ] * * @remarks * - The function utilizes the `fuse.js` library to perform the fuzzy search, allowing for flexible matching of strings. * - The `threshold` value in the Fuse instance controls the fuzziness of the search. A lower threshold makes the search stricter, while a higher threshold allows for more flexible matches. * - The `includeScore` option is enabled to facilitate sorting of results based on their match score. * - The function flattens the search results from all keywords, sorts them by score, and then returns the matching objects. */
export function FuzzySearch<T>(data: T[], key: keyof T, keywords: string[], threshold = 0.3): T[] {
  // create a Fuse instance for fuzzy searching
  const fuse = new Fuse(data, {
    keys: [(key as string)],
    includeScore: true,
    threshold // adjust this value to control the fuzziness
  });

  // search for each keyword and flatten the results
  const results = keywords.flatMap(keyword => fuse.search(keyword));

  // sort the results by score in ascending order
  results.sort((a, b) => (a.score || 0) - (b.score || 0));

  // map the results to the items
  const items = results.map(result => result.item);
  return items;
}