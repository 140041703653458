import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getMonthDateFromIsoString, getShortMonthName } from '@/utils/dateUtils';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { CulmutativeEnum, Report, ReportItem } from '@/utils/hooks/reports/reportTypes';
import { errorToaster } from '@/utils/toaster/toasters';
import applyStylesToColumns from '../shared/columns/applyStylesToColumns';
// eslint-disable-next-line import/no-cycle
import columnFactory from '../shared/columns/columnFactory';
import type { TableRow } from '../shared/types';
import { ColumnType, SidebarCellType } from '../shared/types';
import financialSharedColumnSettings, { mergeSettings } from './financialSharedColumnSetting';
import { getOptions, getUpdateCulmutativeTypeOptions } from './financialTableUtils';
import settings from './settings';
/**
 * Generates an array of column specifications for a hiring plan based on the provided data and settings.
 *
 * @function
 * @param {Object} params - Parameters for column generation.
 * @param {Object} params.data - The data object used to determine column specifications.
 * @returns {Object[]} - An array of column specifications for the hiring plan.
 */
const FinancialsColumnGenerator = (hooks: any, branch_id: number, dates: string[], activeReport: Report | undefined): any[] => {
  // destructure the hooks
  const {
    updateOutput,
    createOutput,
    updateReportItem,
    deleteOutput,
    deleteReportItem,
    setNewRowIndex
  } = hooks;
  const {
    setNewOutputs,
    newOutputs
  } = useZustandStore.getState();
  const colDates = dates; // list of ISO dates

  const mergedSettings = mergeSettings({
    ...financialSharedColumnSettings,
    styles: {
      ...(financialSharedColumnSettings.styles ?? {}),
      shared: {
        ...financialSharedColumnSettings.styles?.shared,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      individual: [{
        accessorKey: 'sidebar',
        // Replace undefined with an empty string or provide the correct value
        style: {
          ...financialSharedColumnSettings.styles?.individual[0]?.style,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 'none'
        }
      }]
    }
  }, settings);

  /**
   * Formats a date string for display in a column header.
   *
   * Checks the report settings to determine how to format:
   * - By month if showMonths is enabled and date is a valid monthly date
   * - By year if showYears is enabled and date is meant for yearly display
   * - By quarter if showQuarters is enabled and date indicates a quarter
   * - As "Total" if showTotal is enabled
   *
   * Returns the formatted date string.
   */

  const columnDateFormatter = (date: string): string | undefined => {
    if (!activeReport?.id || !date) return;
    const {
      showYears,
      showMonths,
      showTotal,
      showQuarters
    } = activeReport.settings || {};

    // Extract month and year from the date
    const {
      month,
      year
    } = getMonthDateFromIsoString(date);
    const isInvalidDate = month === 0 || year === 0;

    // Check if the date is meant for displaying only years (e.g., "total-YYYY").
    const isDateForOnlyYears = date.startsWith('total-');

    // Check if the date is for quarters (e.g., "qN-YYYY").
    const isDateForQuarters = date.startsWith('q');

    // Format for months if enabled and the date is valid
    if (showMonths && !isInvalidDate && !isDateForOnlyYears && !isDateForQuarters) {
      const shortMonth = getShortMonthName(month);
      const shortYear = year.toString().slice(-2); // Get the last two digits of the year
      return `${shortMonth} '${shortYear}`;
    }

    // Format for years if enabled and the date is specifically for yearly display
    if (showYears && isDateForOnlyYears) {
      return date.slice(6); // Extract the year from "total-YYYY"
    }

    // Format for quarters if enabled and the date indicates a quarter
    if (showQuarters && isDateForQuarters) {
      const quarter = date[1]; // Extract quarter number
      const qYear = date.slice(-4); // Extract the year directly from "qN-YYYY"
      return `Q${quarter} ${qYear}`;
    }

    // Return "Total" if the total display is enabled
    if (showTotal) {
      return 'Total';
    }
  };

  /**
   * Determines the column type based on the date format.
   *
   * If the date starts with 'total', returns ColumnType.Year.
   * If the date starts with 'q', returns ColumnType.Quarter.
   * If the date contains '-', returns ColumnType.Month.
   * Otherwise, returns ColumnType.Total.
   */
  const getGeneratedColumnType = (date: string): ColumnType => {
    if (date.startsWith('total')) {
      // Return ColumnType.Year for 'total-yyyy' format
      return ColumnType.Year;
    }
    if (date.startsWith('q')) {
      // Return ColumnType.Quarter for 'qN-yyyy' format
      return ColumnType.Quarter;
    }
    if (date.includes('-')) {
      // Return ColumnType.Month for 'yyyy-mm-dd' format
      return ColumnType.Month;
    }
    return ColumnType.Total; // Return ColumnType.Total for other cases
  };

  /**
   * Creates a function that will add a new account when called.
   * The new account will be added to the specified account group type with default values.
   */
  const addSubAccount = (parentOutput: Output) => {
    return () => {
      createOutput.mutate({
        branch_id,
        name: 'New Account',
        parent_output: parentOutput.id,
        // split to extract the id from 'XXX-{id}-XXX' pattern
        is_top_level: parentOutput?.is_top_level || 0,
        emoji: parentOutput?.emoji || null
      },
      // If the mutation is successful, add the new output to zustand store
      {
        onSuccess: (newOutput: Output) => {
          setNewRowIndex(newOutput.id);
          // this returns the new output
          setNewOutputs([...newOutputs, newOutput]); // Add the new output to the list of outputs to show them eve if showEmptyAccounts on the report is false.
        }
      });
    };
  };
  const renameRow = (original: TableRow, value: string) => {
    // if row type is section rename it with updateReport item
    if (original.type === 'section') {
      updateReportItem.mutate({
        ...original.original_row_object,
        name: value
      });
    }
    if (original.type === 'accountTree' || original.type === 'output') {
      updateOutput.mutate({
        ...original.original_row_object,
        name: value
      });
    }
  };
  const setCulmutativeType = (reportItem: ReportItem, culmutativeType: CulmutativeEnum | null) => {
    updateReportItem.mutate({
      ...reportItem,
      cumulative: culmutativeType
    });
  };
  const convertReportItemLiabilityOrAsset = (reportItem: ReportItem) => {
    updateReportItem.mutate({
      ...reportItem,
      sign: reportItem?.sign === -1 ? 1 : -1 // replace 1 with -1
    });
  };
  const generateCovertOptionLabel = (reportItem: ReportItem) => {
    return reportItem?.sign === -1 ? 'Convert To Asset' : 'Convert To Liability';
  };
  const deletionText = (row: TableRow) => {
    if (row.type === 'output') {
      return 'Delete Account';
    }
    if (row.type === 'formula') {
      return 'Remove Formula';
    }
    if (row.type === 'section') {
      return 'Remove Section';
    }
    if (row.type === 'accountTree') {
      return 'Remove Account';
    }
  };
  const deleteRow = (row: TableRow) => {
    if (row.type === 'output') {
      if (!row.original_row_object.parent_id) {
        // blocking delete on top level account
        errorToaster('Cannot delete top level account');
        return;
      }
      deleteOutput.mutate({
        id: row.original_row_object.id
      });
    }
    if (row.type === 'section' || row.type === 'formula' || row.type === 'accountTree') {
      deleteReportItem.mutate({
        id: row.baseRowItemParent?.id
      });
    }
  };
  const isReportItem = (row: TableRow) => {
    return row.type === 'formula' || row.type === 'section' || row.type === 'accountTree';
  };
  let init = [
  // Default department column
  {
    id: 'sidebar',
    accessorKey: 'sidebar',
    header: activeReport?.name ?? '',
    type: SidebarCellType.FinancialTable,
    getOptions: (row: TableRow, setIsRenaming?: any) => {
      const originalRow = (row.original as TableRow);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const reportItem = (originalRow.baseRowItemParent as ReportItem);
      const currentOutput = originalRow.type === 'output' || originalRow.type === 'accountTree' ? originalRow.original_row_object : null;
      const reportItemOptions = originalRow.type === 'section' ? getUpdateCulmutativeTypeOptions((culmutativeType: CulmutativeEnum | null) => setCulmutativeType(reportItem, culmutativeType)) : [];
      const [addAccountOption, renameOption, convertSignOption, deleteOption] = getOptions(addSubAccount(currentOutput), () => setIsRenaming(true), () => convertReportItemLiabilityOrAsset(reportItem),
      // Modified line
      () => deleteRow(row.original), () => generateCovertOptionLabel(reportItem), deletionText(originalRow));
      const rowOptions = [];
      if (originalRow.type === 'section' || originalRow.type === 'output' && currentOutput?.parent_output) rowOptions.push(renameOption);
      if (originalRow.type === 'accountTree' || originalRow.type === 'output') rowOptions.push(addAccountOption);
      if (isReportItem(originalRow)) {
        rowOptions.push(convertSignOption);
        rowOptions.push(deleteOption);
      }
      if (originalRow.type === 'output' && currentOutput?.parent_output) rowOptions.push(deleteOption); // level we do not allow to delete level 0 outputs

      return rowOptions.concat(reportItemOptions);
    },
    handlerFunctions: {
      renameRow
    }
  }, ...colDates.map(date => ({
    id: date,
    // the field name is set as id of the
    accessorKey: date,
    header: columnDateFormatter(date),
    enableEditing: false,
    type: 'financialView',
    handlerFunctions: {},
    headerStyle: {
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: '700'
    },
    subTitleStyle: {
      textAlign: 'right',
      color: `${colors.subHeaderColor}`,
      fontSize: '10px',
      fontWeight: '500'
    },
    isSubTitle: true,
    columnType: getGeneratedColumnType(date)
  })),
  // Include additional columns
  ...mergedSettings.additionalColumns];
  // apply styles to affected column(s)
  init = init.map(column => applyStylesToColumns(column, mergedSettings));
  // apply column factory to each column
  return init.map(columnFactory);
};
export default FinancialsColumnGenerator;