import _ from 'lodash';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useShallow } from 'zustand/react/shallow';

import type { IDToDatesMap } from '@/components/modelCalculations/calculateFormula';
import { calculateAllFormulas } from '@/components/modelCalculations/calculateFormula';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { generateMonthlyDates, parseUTCDateObject } from '@/utils/dateUtils';
import type { Formula } from '@/utils/types/formulaTypes';

import { useEntries } from '../Calculations/useEntries';
import { useLiveEntries } from '../Calculations/useLiveEntries';
import useDashboards from '../dashboard/useDashboards';
import useGraphs from '../graphs/useGraphs';
import useHiringPlan from '../hiringPlans/useHiringPlan';
import useModels from '../models/useModels';
import useOutputs from '../Outputs/useOutputs';
import useReports from '../reports/useReports';
import {
  useActiveTemplatesQuery,
  useAllTemplatesQuery,
} from '../template/useTemplateHooks';
import useSubscription from '../useSubscription/useSubscription';
import { useFormulas } from './useFormulas';
import { useHiringPlanFormulas } from './useHiringPlanFormulas';

export const CalculateFormulas = () => {
  const {
    startDate,
    endDate,
    branchFormulas,
    activeBranch,
    setMonthlyDates,
    activeBranchModels,
    monthlyDates,
    activeCompany,
    currentDate,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      branchFormulas: state.branchFormulas,
      activeBranch: state.activeBranch,
      setMonthlyDates: state.setMonthlyDates,
      activeBranchModels: state.activeBranchModels,
      monthlyDates: state.monthlyDates,
      activeCompany: state.activeCompany,
      currentDate: state.currentDate,
    })),
  );

  const [, recalculate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const dates = generateMonthlyDates(startDate as Date, endDate as Date);
    setMonthlyDates(dates);
  }, [startDate, endDate]);

  const { calculatedEmployeeData, updatBothDataMap } = CalculationsStore(
    useShallow((state: CalculationsStoreState) => ({
      calculatedEmployeeData: state.calculatedEmployeeData,
      updatBothDataMap: state.updatBothDataMap,
    })),
  );

  // last value of current date ref
  const lastCurrentDate = useRef(currentDate);
  useEffect(() => {
    if (lastCurrentDate.current !== currentDate) {
      updatBothDataMap();
      lastCurrentDate.current = currentDate;
    }
  }, [currentDate]);

  const [lastDataState, setLastDataState] = useState({
    startDate,
    endDate,
    branchFormulas,
    calculatedEmployeeData,
  });

  useDashboards(); // call useDashboards to get dashboard data
  useEntries(); // call useEntries to get entries data
  useOutputs(); // call useOutputs to get branchOutputs in zustand store
  useFormulas(); // call formulas to make sure its fetched and applied to display data
  useModels(); // call models to make sure its fetched and applied to display data
  useHiringPlan(); // call hiring plan to make sure its fetched and applied to display data
  useHiringPlanFormulas(); // call hiring plan formulas to make sure its fetched and applied to display data
  useGraphs(); // call useGraphs to get graph data
  useLiveEntries();
  useReports(); // call useReports to get reports data
  useSubscription();
  useAllTemplatesQuery(); //  preload all templates
  useActiveTemplatesQuery(); //  preload active templates

  const modelIDToDateMap: IDToDatesMap = useMemo(() => {
    const curMap: IDToDatesMap = {};
    if (activeBranchModels && activeBranchModels.length > 0) {
      activeBranchModels.forEach((model) => {
        curMap[model.id] = {
          startDate: parseUTCDateObject(model?.start_date),
          endDate: parseUTCDateObject(model?.end_date),
          startDateString: model?.start_date,
          endDateString: model?.end_date,
        };
      });
    }
    return curMap;
  }, [activeBranchModels]);

  const calculate = useDebouncedCallback(
    (mDates: string[], bFormulas: Formula[], mMap: IDToDatesMap) => {
      calculateAllFormulas(mDates, bFormulas, mMap);
      recalculate();
    },
    500,
    { maxWait: 2000 },
  );

  useEffect(() => {
    // this useEffect is here to handle creation of formula timeline for all formulas. calculating them etc.
    const canCalculate =
      activeBranch && activeBranch.id && branchFormulas?.length > 0;

    // created a condition to check if the formulas are already calculated, otherwise re-calculate
    const curData = {
      startDate,
      endDate,
      branchFormulas,
      calculatedEmployeeData,
      activeBranch,
      activeCompany,
      activeBranchModels,
    };

    const isStartDateChanged = curData.startDate !== lastDataState.startDate;
    const isEndDateChanged = curData.endDate !== lastDataState.endDate;
    const isDataChanged = !_.isEqual(curData, lastDataState);

    if (
      canCalculate && // check if the formulas are already calculated
      (isStartDateChanged || // start date changed
        isEndDateChanged || // end date changed
        isDataChanged) // data has changed // branch formulas changed
    ) {
      setLastDataState(curData);
      calculate(monthlyDates, branchFormulas, modelIDToDateMap);
    }
  }, [
    activeBranch?.id,
    startDate,
    endDate,
    calculatedEmployeeData,
    monthlyDates,
    branchFormulas,
    modelIDToDateMap,
    activeBranchModels,
  ]);

  return null;
};
