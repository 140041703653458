import type { QueryKey } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

/**
 * Generic mutation hook for CREATE operations.
 */
export const useCreateMutation = (
  key: string | QueryKey,
  createFunction: (data: any) => Promise<any>,
  onSuccessCallback?: (data: any) => void,
  onErrorCallback?: (data: any) => void,
  dataKey?: string,
) => {
  const queryClient = useQueryClient();
  const activeCompanyId = useZustandStore(
    useShallow((state: ZustandState) => state.activeCompany?.id ?? null),
  );
  const dynamicQueryKey = Array.isArray(key) ? key : [key, activeCompanyId];

  return useMutation({
    mutationFn: createFunction,
    onSuccess: (data) => {
      queryClient.setQueryData(dynamicQueryKey, (oldData: any) => {
        // Check if data is an array before spreading to handle multiple Assignments
        const newData = Array.isArray(data) ? data : [data];

        if (dataKey) {
          return {
            ...oldData,
            [dataKey]: [...oldData[dataKey], ...newData],
          };
        }

        // Check if oldData is an array before spreading
        if (Array.isArray(oldData)) {
          const newCacheData = [...oldData, ...newData];
          return newCacheData;
        }

        // Handle the case when oldData is not an array, e.g., set it as an array with data
        return newData;
      });

      if (onSuccessCallback) onSuccessCallback(data);
    },

    onError: onErrorCallback,
  });
};

/**
 * Generic mutation hook for UPDATE operations.
 */
export const useUpdateMutation = (
  key: string | QueryKey,
  updateFunction: (data: any) => Promise<any>,
  onSuccessCallback?: (data: any) => void,
  dataKey?: string,
  preprocessFunction?: (data: any) => any,
) => {
  const queryClient = useQueryClient();
  const activeCompanyId = useZustandStore(
    useShallow((state: ZustandState) => state.activeCompany?.id ?? null),
  );
  const dynamicQueryKey = Array.isArray(key) ? key : [key, activeCompanyId];

  return useMutation({
    mutationFn: updateFunction,
    onSuccess: (data: any) => {
      queryClient.setQueryData(dynamicQueryKey, (oldData: any) => {
        // eslint-disable-next-line no-param-reassign
        data = preprocessFunction ? preprocessFunction(data) : data; // allowing to process the data before adding to the cache

        if (Array.isArray(data)) {
          // Handle bulk updates
          const updatedDataMap = new Map(
            data.map((item: any) => [item.id, item]),
          );
          return oldData.map(
            (item: any) => updatedDataMap.get(item.id) || item,
          );
        }
        /**
         * Check if dataKey is passed
         *
         * If passed, will lookup updated data from response[dataKey] instead of response directly.
         *
         * Allows handling different response formats.
         */
        if (dataKey) {
          return {
            ...oldData,
            [dataKey]: oldData[dataKey].map((item: any) =>
              item.id === data.id ? data : item,
            ),
          };
        }

        // Handle single updates
        return oldData?.map((item: any) => (item.id === data.id ? data : item));
      });

      if (onSuccessCallback) onSuccessCallback(data);
    },
  });
};

/**
 * Generic mutation hook for DELETE operations.
 */
export const useDeleteMutation = (
  key: string | QueryKey,
  deleteFunction: (data: { id: any }) => Promise<any>,
  onSuccessCallback?: (id: number) => void,
  dataKey?: string,
) => {
  const queryClient = useQueryClient();
  const activeCompanyId = useZustandStore(
    useShallow((state: ZustandState) => state.activeCompany?.id ?? null),
  );
  const dynamicQueryKey = Array.isArray(key) ? key : [key, activeCompanyId];

  return useMutation({
    mutationFn: deleteFunction,
    // Use the variables passed to the mutate function
    onSuccess: (_, variables) => {
      const { id } = variables; // Destructure the ID directly from the variables

      queryClient.setQueryData(dynamicQueryKey, (oldData: any) => {
        if (dataKey) {
          return {
            ...oldData,
            [dataKey]: oldData[dataKey].filter((item: any) => item?.id !== +id),
          };
        }

        if (oldData) {
          // Filter out the deleted item using the ID from the variables
          return oldData.filter((item: any) => item?.id !== +id);
        }
        return oldData;
      });

      // Call the success callback with the ID from the variables
      if (onSuccessCallback) onSuccessCallback(id);
    },
  });
};

export const useBulkDeleteMutation = (
  key: string | QueryKey,
  deleteFunction: (data: { ids: number[] }) => Promise<any>,
  onSuccessCallback?: (ids: number[]) => void,
) => {
  const queryClient = useQueryClient();
  const activeCompanyId = useZustandStore(
    useShallow((state: ZustandState) => state.activeCompany?.id ?? null),
  );
  const dynamicQueryKey = Array.isArray(key) ? key : [key, activeCompanyId];

  return useMutation({
    mutationFn: deleteFunction,
    // Use the variables passed to the mutate function
    onSuccess: (_, variables) => {
      const { ids } = variables; // Destructure the IDs directly from the variables
      queryClient.setQueryData(dynamicQueryKey, (oldData: any) => {
        if (oldData) {
          // Filter out the deleted items using the IDs from the variables
          return oldData.filter((item: any) => !ids.includes(item?.id));
        }
        return oldData;
      });

      // Call the success callback with the IDs from the variables
      if (onSuccessCallback) onSuccessCallback(ids);
    },
  });
};
