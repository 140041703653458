import { Auth0Provider } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import { vercelEnv } from '@/utils/AppConfig';
const Auth0Wrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const redirectUri = `${window.location.origin}/login`;
  const existingDistinctId = localStorage.getItem('distincId');
  const distinctId = existingDistinctId || uuidv4();
  if (!existingDistinctId) {
    localStorage.setItem('distincId', distinctId);
  }
  const domain = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL_LIVE : process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL_DEV;
  const clientId = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_LIVE : process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_DEV;
  const audience = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_AUTH0_AUDIENCE_URL_LIVE : process.env.NEXT_PUBLIC_AUTH0_AUDIENCE_URL_DEV;
  return <Auth0Provider domain={domain || ''} clientId={clientId || ''} cacheLocation="localstorage" authorizationParams={{
    redirect_uri: `${redirectUri}?distincId=${distinctId}`,
    audience: audience || ''
  }} data-sentry-element="Auth0Provider" data-sentry-component="Auth0Wrapper" data-sentry-source-file="Auth0Wrapper.tsx">
      {children}
    </Auth0Provider>;
};
export default Auth0Wrapper;