// eslint-disable-next-line simple-import-sort/imports
import '@/styles/autocomplete.scss';
import '@/styles/datepicker.scss';
import '@/styles/formulaField.css';
import '@/styles/global.scss';
import '@/styles/loader.css';
import '@/styles/rangeCalender.scss';
import '@/styles/scss/index.scss';
import '@/styles/select.scss';
import '@/styles/slider.scss';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import 'animate.css';
import 'nprogress/nprogress.css';
import 'rc-slider/assets/index.css';
import 'react-modern-drawer/dist/index.css';
import 'react-resizable/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'regenerator-runtime/runtime';
import '../../i8n';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import AblyLayout from '@/ably/layout/AblyLayout';
import { DataProvider } from '@/miscellaneous/helper/DataProvider';
import { FormulaProvider } from '@/miscellaneous/helper/FormulaProvider';
import NoSSR from '@/miscellaneous/helper/NoSSR';
import Auth0Wrapper from '@/miscellaneous/layouts/Auth0Wrapper';
import HooksLayout from '@/miscellaneous/layouts/HooksLayout';
import UnifiedLayout from '@/miscellaneous/layouts/UnifiedLayout';
import queryClient from '@/utils/queryClient';
import { del, get, set } from 'idb-keyval';
import { hotjar } from 'react-hotjar';
import { vercelEnv } from '@/utils/AppConfig';
const createIDBPersister = (idbValidKey: IDBValidKey = 'application-cache') => {
  return ({
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    }
  } as Persister);
};
const persister = createIDBPersister('application-cache');
const MyApp = ({
  Component,
  pageProps
}: AppProps) => {
  const router = useRouter();
  NProgress.configure({
    showSpinner: false
  });

  // Get Hotjar ID from environment variables
  const hotjarId = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_HOTJAR_ID_LIVE : process.env.NEXT_PUBLIC_HOTJAR_ID_DEV;

  // Initialize Hotjar on the client side
  useEffect(() => {
    hotjar.initialize({
      id: +(hotjarId as string),
      sv: 6
    });
  }, []);
  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };
    const handleComplete = () => {
      NProgress.done(false);
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
    return () => {
      router.events.on('routeChangeStart', handleStart);
      router.events.on('routeChangeComplete', handleComplete);
      router.events.on('routeChangeError', handleComplete);
    };
  }, [router]);
  return <PersistQueryClientProvider persistOptions={{
    persister
  }} client={queryClient} data-sentry-element="PersistQueryClientProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <NoSSR data-sentry-element="NoSSR" data-sentry-source-file="_app.tsx">
        <Auth0Wrapper data-sentry-element="Auth0Wrapper" data-sentry-source-file="_app.tsx">
          <DataProvider data-sentry-element="DataProvider" data-sentry-source-file="_app.tsx" />
          <main>
            <HooksLayout data-sentry-element="HooksLayout" data-sentry-source-file="_app.tsx">
              <FormulaProvider data-sentry-element="FormulaProvider" data-sentry-source-file="_app.tsx" />
              <UnifiedLayout data-sentry-element="UnifiedLayout" data-sentry-source-file="_app.tsx">
                <AblyLayout data-sentry-element="AblyLayout" data-sentry-source-file="_app.tsx">
                  <ToastContainer data-sentry-element="ToastContainer" data-sentry-source-file="_app.tsx" />
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </AblyLayout>
              </UnifiedLayout>
            </HooksLayout>
          </main>
        </Auth0Wrapper>
      </NoSSR>
      <ReactQueryDevtools initialIsOpen data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
    </PersistQueryClientProvider>;
};
export default appWithTranslation(MyApp);