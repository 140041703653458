export const companySectorsData = [
  'Fintech',
  'Healthtech',
  'SaaS',
  'E-commerce',
  'Artificial Intelligence (AI) and Machine Learning (ML)',
  'Edtech',
  'Cybersecurity',
  'Biotechnology',
  'Internet of Things (IoT)',
  'Clean Energy and Sustainability',
  'Proptech (Property Technology)',
  'Augmented Reality (AR) and Virtual Reality (VR)',
  'Foodtech',
  'Autonomous Vehicles',
  'Robotics',
  'Agtech (Agriculture Technology)',
  'Insurtech (Insurance Technology)',
  'Medtech (Medical Technology)',
  'Blockchain and Cryptocurrency',
  'Digital Health',
  'MarTech (Marketing Technology)',
  'HRTech (Human Resources Technology)',
  'Travel and Tourism Technology',
  'Entertainment and Media Technology',
  'Retail',
  'Other',
  'Legal',
  'SportsTech',
  'Fashion',
  'AdTech (Advertising Technology)',
  'Gaming and Esports',
  'Manufacturing and Industrial Automation',
  'Banking and Financial Services',
  'Construction',
  'Real Estate',
  'Consulting Services',
  'Hardware Manufacturing',
  'Marketplace',
  'Hospitality',
  'Healthcare',
  'Energy',
  'Professional Services',
  'Design',
  'Event Planning',
  'Content Creation',
];
