import { title } from 'process';
import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { TbBrain, TbCheck, TbGitBranch, TbX } from 'react-icons/tb';
import { useShallow } from 'zustand/react/shallow';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import type { Prompt } from '@/utils/types/AITypes';
import Tooltip from '../common/Tooltip/Tooltip';
import styles from './AIView.module.css';
import GlowAnimationWrapper from './GlowAnimationWrapper/GlowAnimationWrapper';
type RenderIconsProps = {
  resetItems: () => void;
  mergeItems: (mergeToNewBranch?: boolean) => void;
  mergeToNewScenario: () => void;
  openChatWindow: (message?: string | undefined, messageDisplay?: string | undefined) => void;
  chatContentState?: string;
};
export type AIItemProps = {
  title: string;
  id: number;
};
const renderItems = (items: Prompt[], removeItems: (id: number) => void, onClickItem?: (id: number) => void, showDeleteOnAll?: boolean, optionColor?: string, textColor?: string) => {
  const itemMaxLength = 30;
  return items.map((item, index) =>
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <p key={title} className={`${styles.aiItem} `} style={{
    backgroundColor: optionColor,
    color: textColor
  }}>
      <Tooltip element={<span className="flex h-full w-auto cursor-pointer items-center" onClick={() => onClickItem && onClickItem(item.id)}>
            {item.title && item.title.length < itemMaxLength // handle if item.title is too long
      ? item.title : `${item.title?.substring(0, itemMaxLength)}...`}
          </span>} content={(item.title as string)} />

      {(showDeleteOnAll || index === items.length - 1) && <button aria-label="delete item" type="button" onClick={() => {
      removeItems(item.id);
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.PROMPT_WAS_REMOVED, {
        prompt: item.title
      });
    }}>
          <IoClose />
        </button>}
    </p>);
};

// This function handles rendering the buttons in the AI view
const RenderIcons = ({
  resetItems,
  mergeItems,
  mergeToNewScenario,
  openChatWindow,
  chatContentState
}: RenderIconsProps): React.ReactElement => {
  const options = [{
    icon: <TbGitBranch size={18} strokeWidth={1.5} />,
    alt: 'branch-icon',
    onclick: () => mergeToNewScenario && mergeToNewScenario(),
    tooltipContent: 'Merge to new scenario'
  }, {
    icon: <TbBrain size={18} strokeWidth={1.5} />,
    alt: 'explain-icon',
    onclick: () => openChatWindow(chatContentState, `Explain the current AI view`),
    tooltipContent: 'Explain scenario with AI'
  }, {
    icon: <TbCheck size={18} strokeWidth={2} />,
    alt: 'merge-icon',
    onclick: () => mergeItems(),
    tooltipContent: 'Merge to current branch'
  }, {
    icon: <TbX size={18} />,
    alt: 'x-icon',
    onclick: () => resetItems(),
    tooltipContent: 'Exit AI view'
  }];
  return <div className={styles.optionIcons} data-sentry-component="RenderIcons" data-sentry-source-file="AIItemView.tsx">
      {options.map(option => <button key={option.alt} className="button" onClick={option.onclick} type="button" aria-label="icon button">
          <Tooltip element={option.icon} content={(option.tooltipContent as string)} />
        </button>)}
    </div>;
};
interface AIViewProps {
  title: string;
  items: Prompt[];
  removeItems: (id: number) => void;
  resetItems: () => void;
  mergeItems: (mergeToNewBranch?: boolean) => void;
  mergeToNewScenario: () => void;
  openChatWindow: (message?: string, messageDisplay?: string) => void;
  chatContentState: string;
  onClickItem?: (id: number) => void;
  showDeleteOnAll?: boolean;
  optionColor?: string;
  textColor?: string;
}
const AIItemView = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  title,
  items,
  removeItems,
  resetItems,
  mergeItems,
  mergeToNewScenario,
  openChatWindow,
  chatContentState,
  onClickItem = () => {},
  showDeleteOnAll = false,
  optionColor = colors.aiOptionBg,
  textColor = colors.black
}: AIViewProps) => {
  const {
    activeBranch
  } = useZustandStore(useShallow(state => ({
    activeBranch: state.activeBranch
  })));
  const activeBranchRef = useRef(activeBranch);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized && items.length > 0) {
      setInitialized(true);
      activeBranchRef.current = activeBranch;
      return;
    }
    if (initialized && activeBranch !== activeBranchRef.current && items.length > 0) {
      resetItems();
      activeBranchRef.current = activeBranch;
    }
  }, [activeBranch, resetItems, items, initialized]);
  if (!items?.length) return <span />;
  return <GlowAnimationWrapper data-sentry-element="GlowAnimationWrapper" data-sentry-component="AIItemView" data-sentry-source-file="AIItemView.tsx">
      <div className={styles.aiContainer}>
        <div className={styles.firstContainer}>
          <p className={styles.title}>{title}</p>
          {renderItems(items, removeItems, onClickItem, showDeleteOnAll, optionColor, textColor)}
        </div>
        <RenderIcons resetItems={resetItems} mergeItems={mergeItems} mergeToNewScenario={mergeToNewScenario} openChatWindow={openChatWindow} chatContentState={chatContentState} data-sentry-element="RenderIcons" data-sentry-source-file="AIItemView.tsx" />
      </div>
    </GlowAnimationWrapper>;
};
export default AIItemView;