/**
 * ChatGPTMessage Component
 *
 * Description:
 * The `ChatGPTMessage` component is responsible for displaying individual messages within the chat interface.
 * It supports different styles for user and bot messages and includes an animation for bot messages as they appear.
 * After a bot message is rendered, interaction icons are displayed to allow the user to react or further interact
 * with the message, such as selecting options to modify the response.
 */
import { parse } from 'marked';
import type { FC, JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import InteractionIcons from '@/components/common/CopilotChat/CopilotChatComponents/InteractionIcons';
import styles from '@/styles/scss/copilotChat.module.scss';
interface IMessageProps {
  /**
   * The sender of the message, either "user" or "bot".
   */
  sender: 'user' | 'bot';

  /**
   * The text content of the message.
   */
  text: string;

  /**
   * The unique identifier of the message.
   */
  id: string;

  /**
   * Function to handle adding an option to the chat.
   * @param option - The selected option.
   * @param id - The ID of the message to replace with the option.
   */
  addOptionToChat: (option: string, id: string) => void;

  /**
   * Optional function to set the disabled state of the chat input.
   * @param isDisabled - Boolean value indicating whether the chat input should be disabled.
   */
  setIsDisabled?: (isDisabled: boolean) => void;
  messagesEndRef?: React.RefObject<HTMLDivElement> | null | undefined;
  className?: string;
  interactionIcons?: boolean;
  onAnimationEnd?: () => void;
}

/**
 * MessageElement component responsible for rendering an individual chat message.
 * @param id - The unique identifier of the message.
 * @param sender - The sender of the message, either "user" or "bot".
 * @param text - The text content of the message.
 * @param addOptionToChat - Function to handle adding an option to the chat.
 * @param setIsDisabled - Optional function to set the disabled state of the chat input.
 * @returns The MessageElement component.
 */
const Message: FC<IMessageProps> = ({
  id,
  sender,
  text,
  addOptionToChat,
  setIsDisabled,
  messagesEndRef,
  className,
  interactionIcons = true,
  onAnimationEnd
}): JSX.Element => {
  const [isShowInteractionIcons, setIsShowInteractionIcons] = useState(false);
  const [animationOver, setAnimationOver] = useState(false);
  /**
   * Callback function called when Typist animation ends.
   * Sets the state to show interaction icons and potentially enables the chat input.
   */
  const animationEnd = () => {
    setIsShowInteractionIcons(true);
    if (setIsDisabled) {
      setIsDisabled(false);
    }
    if (onAnimationEnd) onAnimationEnd();
  };

  /**
   *  Calculates the typing time for the message based on the number of words.
   */
  const calculateTypingTime = () => {
    const words = text.split(' ');
    return words.length * 80;
  };

  /**
   * Sets a timeout to show the interaction icons after the message has been rendered.
   */
  useEffect(() => {
    setTimeout(() => {
      animationEnd();
      setAnimationOver(true);
    }, calculateTypingTime());
  }, []);
  function getMarkedText(t: string) {
    const prefix = 'ooolllooo';
    const prefixWitht = `${prefix}${t}`; // added to prevent deleting the first word
    const processedText = prefixWitht.replace(/\n/g, '<br/>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>  ');
    const parsed = parse(processedText);
    const result = parsed.replace(new RegExp(prefix, 'g'), '');
    return {
      __html: result
    };
  }
  const messageRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!messagesEndRef) return;
    let interval: NodeJS.Timeout;
    if (sender === 'user') {
      messagesEndRef.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }
    if (!animationOver && sender === 'bot') {
      interval = setInterval(() => {
        messagesEndRef.current?.scrollTo(0, messagesEndRef.current.scrollHeight + 30);
      }, 400);
    }
    if (animationOver) {
      messagesEndRef.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }

    // Cleanup function
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [animationOver, messagesEndRef]);
  return <div
  // eslint-disable-next-line tailwindcss/no-custom-classname
  className={className || `
                ${styles.MessageElement} ${sender === 'user' ? styles.MessageElementForUser : styles.MessageElementForBot} ${sender === 'bot' && 'animate__animated animate__fadeInRight'}
            `} ref={messageRef} data-sentry-component="Message" data-sentry-source-file="Message.tsx">
      {sender === 'user' ? <p>{text}</p> : animationOver ?
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={getMarkedText(text)} /> : <TypeAnimation sequence={[text]} wrapper="p" cursor={false} repeat={0} speed={99} // 99 is the max speed for the TypeAnimation component
    />}

      {sender === 'bot' && isShowInteractionIcons && interactionIcons ? <InteractionIcons text={text} id={id} addOptionToChat={addOptionToChat} /> : null}
    </div>;
};
export default Message;