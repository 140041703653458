import { DndContext, rectIntersection } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { createContext, useContext, useMemo } from 'react';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import flattenArray from '@/utils/helpers/flattenArray'; // Adjust the import path as needed

// eslint-disable-next-line import/no-cycle
import useDndTableLogic from '../useDndTableLogic';
const DndContextInstance = createContext(null);
export const useDndContext = () => {
  return useContext(DndContextInstance);
};
interface DnDContextProviderProps {
  children: React.ReactNode;
}

/**
 * Provides a DnD context for drag-and-drop functionality with sorting capabilities.
 * @param children - The child components that will be part of the DnD context.
 * @param data - The data array used for sorting items in the DnD context.
 */
const DnDContextProvider = ({
  children
}: DnDContextProviderProps) => {
  const {
    financialDrawerTablerows,
    currentTableRows
  } = DataStore((state: DataStoreState) => state);
  const flatData = useMemo(() => flattenArray(currentTableRows).concat(financialDrawerTablerows) || [], [currentTableRows, financialDrawerTablerows]);

  // Get DnD logic hooks
  const {
    sensors,
    handleDragEnd
  } = useDndTableLogic(flatData);
  return <DndContext sensors={sensors} collisionDetection={rectIntersection} onDragEnd={handleDragEnd} data-sentry-element="DndContext" data-sentry-component="DnDContextProvider" data-sentry-source-file="DNDContextProvider.tsx">
      <SortableContext items={flatData} strategy={verticalListSortingStrategy} data-sentry-element="SortableContext" data-sentry-source-file="DNDContextProvider.tsx">
        {children}
      </SortableContext>
    </DndContext>;
};
export default DnDContextProvider;