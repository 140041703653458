const ALLCOMPANIES = 'allCompanies';
const BRANCHBYCOMPANYID = 'branchByCompanyId';
const ALLMODELSBYALLBRANCHID = 'allModelByAllBranchId';
const ALLMODELS = 'allModels';
const MODELSBYBRANCHID = 'modelsByBranchId';
const ALLTEMPLATES = 'allTemplates';
const ACTIVETEMPLATES = 'templatesInUse';
const CREATEROLE = 'createRole';
const APPLYTEMPLATE = 'applyTemplate';
const REMOVETEMPLATE = 'removeTemplate';
const HIRING_PLANS = 'hiringPlans';
const REPORTS = 'reports';
const ALLEMPLOYEES = 'allEmployees';
const ENTRIES = 'entries';
const LIVEENTRIES = 'liveEntries';
const GRAPHSBYCOMPANYID = 'graphsByCompanyId';
const DASHBOARDSBYCOMPANY = 'dashboardsByCompany';
const GETALLOUTPUTS = 'getAllOutputs';
const GRAPHSCREATE = 'graphsCreate';
const BRANCHESHIRINGSETTINGS = 'branchesHiringSettings';
const EMPLOYEEROLESBYCOMPANY = 'employeeRolesByCompany';
const EMPLOYEE_COLUMNS = 'employeeColumns';
const ALLFORMULAS = 'allFormulas';
const USER_ME = 'userMe';
const DATES_UPDATE = 'datesUpdate';
const REFRESH_TOKEN = 'refreshToken';
const CUSTOM_TEMPLATES = 'customTemplates';
const INTEGRATIONS = 'integrations';
const API_DECK_VAULT_TOKEN = 'apiDeckVaultToken';
const API_DECK_CONNECTORS = 'apiDeckConnectors';
const VALIDATE_QUESTIONS_AND_ANSWERS = 'validateQuestionsAndAnswers';
const AI_EXPAND_TEXT = 'aiExpandText';
const TEMPLATE_ASSIGNER = 'templateAssigner';
const FINISH_ONBOARDING = 'finishOnboarding';
const EXTRACT_FIELDS_COMPANY_DESCRIPTIONS = 'extractFieldsCompanyDescriptions';
const EXTRACT_GOALS_COMPANY_DESCRIPTIONS = 'extractGoalsCompanyDescriptions';
const PREDICT_COMPANY_PRODUCTS = 'predictCompanyProducts';
const SCRAPE_COMPANY = 'scrapeCompany';
const FAST_ONBOARDING = 'fastOnboarding';
const SEND_ERROR_REPORT = 'sendErrorReport';
const AI_VOICE_RESPONSE = 'aiVoiceResponse';
const GET_PROMPT_SUMMARY = 'getPromptSummary';
const SUBSCRIPTION_STATUS = 'subscriptionStatus';
const PAYMENT_INTENT = 'paymentIntent';
const GET_SUBSCRIPTION_PRODUCTS = 'getSubscriptionProducts';
const CREATE_SUBSCRIPTION = 'createSubscription';
const BILLING_PORTAL_LINK = 'getBillingPortalLink';
const GET_GENERAL_CHAT_RESPONSE = 'getGeneralChatResponse';
const END_TRIAL_SUBSCRIPTION = 'endTrialSubscription';
export const QUERYKEYS = Object.freeze({
  END_TRIAL_SUBSCRIPTION,
  GET_GENERAL_CHAT_RESPONSE,
  BILLING_PORTAL_LINK,
  CREATE_SUBSCRIPTION,
  GET_SUBSCRIPTION_PRODUCTS,
  PAYMENT_INTENT,
  SUBSCRIPTION_STATUS,
  GET_PROMPT_SUMMARY,
  AI_VOICE_RESPONSE,
  SEND_ERROR_REPORT,
  FAST_ONBOARDING,
  PREDICT_COMPANY_PRODUCTS,
  EXTRACT_FIELDS_COMPANY_DESCRIPTIONS,
  EXTRACT_GOALS_COMPANY_DESCRIPTIONS,
  FINISH_ONBOARDING,
  TEMPLATE_ASSIGNER,
  API_DECK_VAULT_TOKEN,
  ALLCOMPANIES,
  ALLFORMULAS,
  BRANCHBYCOMPANYID,
  MODELSBYBRANCHID,
  ALLMODELSBYALLBRANCHID,
  ALLMODELS,
  ALLTEMPLATES,
  ACTIVETEMPLATES,
  CUSTOM_TEMPLATES,
  CREATEROLE,
  APPLYTEMPLATE,
  REMOVETEMPLATE,
  ALLEMPLOYEES,
  GRAPHSBYCOMPANYID,
  DASHBOARDSBYCOMPANY,
  GETALLOUTPUTS,
  GRAPHSCREATE,
  HIRING_PLANS,
  BRANCHESHIRINGSETTINGS,
  EMPLOYEEROLESBYCOMPANY,
  EMPLOYEE_COLUMNS,
  ENTRIES,
  LIVEENTRIES,
  REPORTS,
  USER_ME,
  DATES_UPDATE,
  REFRESH_TOKEN,
  INTEGRATIONS,
  API_DECK_CONNECTORS,
  VALIDATE_QUESTIONS_AND_ANSWERS,
  AI_EXPAND_TEXT,
  SCRAPE_COMPANY
});