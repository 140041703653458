import { isString } from 'lodash';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';

import DataStore from '@/miscellaneous/store/DataStore';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import useSubscription from './useSubscription';

export const validateSubscription = () => {
  const { setShowPaymentModal } = useUtilsStore.getState();
  const { subscriptionDetails } = DataStore.getState();

  if (!subscriptionDetails) {
    setShowPaymentModal(true);
    return false;
  }

  if (
    subscriptionDetails.status !== 'active' &&
    subscriptionDetails.status !== 'trialing'
  ) {
    setShowPaymentModal(true);
    return false;
  }

  toast.success('You have an active subscription!');
  setShowPaymentModal(false);

  return true;
};

/**
 * we check in useEffect if onboarding is done + no subscription for company + confetty animation is not active -> show payment modal
 */
export const useValidateSubscription = () => {
  const { activeCompany, isLoggedIn, userMe } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompany: state.activeCompany,
      isLoggedIn: state.isLoggedIn,
      userMe: state.userMe,
    })),
  );
  const { isConfettiAnimation, setShowPaymentModal, appLoader } = useUtilsStore(
    useShallow((state: UtilsStoreState) => ({
      isConfettiAnimation: state.isConfettiAnimation,
      setShowPaymentModal: state.setShowPaymentModal,
      appLoader: state.appLoader,
    })),
  );
  const { data, isSuccess } = useSubscription();

  useEffect(() => {
    const subscriptionDetails = data?.[0];
    const isInvalidSubscription =
      !subscriptionDetails?.id ||
      (subscriptionDetails.status !== 'active' &&
        subscriptionDetails.status !== 'trialing');
    if (
      !!isLoggedIn && // if user is logged in
      !isString(userMe) &&
      !userMe?.is_superuser && // if user is not a superuser
      !!activeCompany?.onboarding_finished_date && // if onboarding is done
      isSuccess && // if subscription data is fetched successfully
      isInvalidSubscription && // if subscription is invalid or non existent
      !isConfettiAnimation && // if confetti animation is not active
      !appLoader // if app loader is not active
    ) {
      setShowPaymentModal(true);
    } else {
      setShowPaymentModal(false);
    }
  }, [
    activeCompany,
    isSuccess,
    data,
    isConfettiAnimation,
    isLoggedIn,
    appLoader,
    userMe,
  ]);
};
