import { create } from 'zustand';

type UtilsStoreDataState = {
  appLoader: boolean;
  financialsAnimated: boolean;
  hiringplanAnimated: boolean;
  assumptionsAnimated: boolean;
  modelsAnimated: boolean;
  isConfettiAnimation: boolean;
  displayCounter: boolean;
  countLoader: boolean;
  companyUrlObjectForScrape: {
    url: string;
    direction: 'login' | 'signup';
  } | null;
  createAndScrapeCompany: boolean;
  isTutorialsModalOpen: boolean;
  rowIDToScrollTo: string | null;
  parentRowIDsToScrollTo: string[];
  hotWordTriggered: boolean;
  hotWordListeningMode: boolean;
  addVoiceResponse: boolean;
  showPaymentModal: boolean;
  isIntegrationPopUpOpen: boolean;
  isHotWordListener: boolean;
};

type UtilsStoreActionsState = {
  setAppLoader: (loader: boolean) => void;
  resetStore: () => void;
  setFinancialsAnimated: (animated: boolean) => void;
  setHiringPlanAnimated: (animated: boolean) => void;
  setAssumptionsAnimated: (animated: boolean) => void;
  setModelsAnimated: (animated: boolean) => void;
  setConfettiAnimation: (animation: boolean) => void;
  setDisplayCounter: (display: boolean) => void;
  setCountLoader: (count: boolean) => void;
  setCompanyUrlObjectForScrape: (
    url: {
      url: string;
      direction: 'login' | 'signup';
    } | null,
  ) => void;
  setCreateAndScrapeCompany: (isDone: boolean) => void;
  setAnimationActive: (animation: boolean) => void;
  setIsTutorialsModalOpen: (isOpen: boolean) => void;
  setRowIDToScrollTo: (
    rowID: string | null,
    parentRowIDsToScrollTo?: string[],
  ) => void;
  setHotWordTriggered: (triggered: boolean) => void;
  setHotWordListeningMode: (mode: boolean) => void;
  setAddVoiceResponse: (addVoiceResponse: boolean) => void;
  setShowPaymentModal: (show: boolean) => void;
  setIsIntegrationPopUpOpen: (isOpen: boolean) => void;
  setIsHotWordListener: (isListener: boolean) => void;
};

export type UtilsStoreState = UtilsStoreDataState & UtilsStoreActionsState;

export const defaultUtilsState: UtilsStoreDataState = {
  appLoader: false,
  financialsAnimated: false,
  hiringplanAnimated: false,
  assumptionsAnimated: false,
  modelsAnimated: false,
  isConfettiAnimation: false,
  displayCounter: false,
  countLoader: false,
  companyUrlObjectForScrape: null,
  createAndScrapeCompany: false,
  isTutorialsModalOpen: false,
  rowIDToScrollTo: null,
  parentRowIDsToScrollTo: [],
  hotWordTriggered: false,
  hotWordListeningMode: true,
  addVoiceResponse: false,
  showPaymentModal: false,
  isIntegrationPopUpOpen: false,
  isHotWordListener: false,
};

/**
 * Creates a Zustand store for utility-related state and actions.
 *
 * The store provides the following state and actions:
 *
 * - `appLoader`: a boolean indicating whether the app loader should be displayed
 * - `setAppLoader`: a function to set the `appLoader` state
 * - `resetStore`: a function to reset the store to its default state
 *
 * This store can be used to manage utility-related state and actions across the application.
 */
const useUtilsStore = create<UtilsStoreState>((set) => ({
  ...defaultUtilsState,
  setAppLoader: (loader: boolean) => set({ appLoader: loader }),
  setDisplayCounter: (display: boolean) => set({ displayCounter: display }),
  resetStore: () => set({ ...defaultUtilsState }),
  setCountLoader: (count: boolean) => set({ countLoader: count }),
  setFinancialsAnimated: (animated: boolean) =>
    set({ financialsAnimated: animated }),
  setHiringPlanAnimated: (animated: boolean) =>
    set({ hiringplanAnimated: animated }),
  setAssumptionsAnimated: (animated: boolean) =>
    set({ assumptionsAnimated: animated }),
  setModelsAnimated: (animated: boolean) => set({ modelsAnimated: animated }),
  setConfettiAnimation: (animation: boolean) =>
    set({ isConfettiAnimation: animation }),
  setCompanyUrlObjectForScrape: (
    urlObject: {
      url: string;
      direction: 'login' | 'signup';
    } | null,
  ) => set({ companyUrlObjectForScrape: urlObject }),
  setCreateAndScrapeCompany: (isDone: boolean) =>
    set({ createAndScrapeCompany: isDone }),
  setAnimationActive: (animation: boolean) =>
    set({
      isConfettiAnimation: animation,
      financialsAnimated: animation,
      hiringplanAnimated: animation,
      assumptionsAnimated: animation,
      modelsAnimated: animation,
    }),
  setRowIDToScrollTo: (
    rowID: string | null,
    parentRowIDsToScrollTo: string[] = [],
  ) => {
    set({ rowIDToScrollTo: rowID, parentRowIDsToScrollTo });
  },

  setHotWordTriggered: (triggered: boolean) =>
    set({ hotWordTriggered: triggered }),

  setIsTutorialsModalOpen: (isOpen: boolean) =>
    set({ isTutorialsModalOpen: isOpen }),

  setHotWordListeningMode: (mode: boolean) =>
    set({ hotWordListeningMode: mode }),

  setAddVoiceResponse: (addVoiceResponse: boolean) => set({ addVoiceResponse }),
  setShowPaymentModal: (show: boolean) => set({ showPaymentModal: show }),
  setIsIntegrationPopUpOpen: (isOpen: boolean) =>
    set({ isIntegrationPopUpOpen: isOpen }),

  setIsHotWordListener: (isListener: boolean) =>
    set({ isHotWordListener: isListener }),
}));

export default useUtilsStore;
