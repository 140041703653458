import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';
import ChatDrawer from '@/components/common/CopilotChat/CopilotChatComponents/ChatDrawer';
import StripeCheckout from '@/components/common/StripeComponents/StripeCheckout/StripeCheckout';
import PaymentModal from '@/components/common/StripeComponents/StripeProvider';
import AddIntegrationsPopup from '@/components/Settings/Integrations/IntegrationsPoup/AddIntegrationsPopup';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useHotWordListener from '@/utils/hooks/useHotWordListener/useHotWordListener';

// Global components layout wrapper
const GlobalComponentsLayout = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const {
    isHotWordListener
  } = useUtilsStore(useShallow(state => ({
    isHotWordListener: state.isHotWordListener
  })));
  const {
    showPaymentModal
  } = useUtilsStore(useShallow(state => ({
    showPaymentModal: state.showPaymentModal
  })));

  // listen for the trigger phrases and set the state accordingly
  useHotWordListener(isHotWordListener);
  return <>
      {children}
      <AddIntegrationsPopup data-sentry-element="AddIntegrationsPopup" data-sentry-source-file="GlobalComponentsLayout.tsx" />
      {showPaymentModal && <PaymentModal key={router.pathname}>
          <StripeCheckout />
        </PaymentModal>}
      <ChatDrawer data-sentry-element="ChatDrawer" data-sentry-source-file="GlobalComponentsLayout.tsx" />
    </>;
};
export default GlobalComponentsLayout;