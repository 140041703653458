/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import Message from '@/components/common/CopilotChat/CopilotChatComponents/Message';
import type { CombinedEntry } from '@/miscellaneous/store/calulcationsStoreHelper';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import aiIcon from '@/public/assets/icons/search.png';
import aiGif from '@/public/assets/images/AIWorking.gif';
import { getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { getDatesToEntriesMap } from '../../FinancialDrawerContent/FinancialDrawerContent';
import styles from './drawerAISuggestion.module.scss';
const todayStringDate = getDateStringFormat(parseUTCDateObject());
/**
 * Reduces a list of entries for API consumption by keeping the top 10 most expensive transactions
 * and aggregating the rest into a single "Smaller Transactions" entry.
 *
 * @param entries - Array of CombinedEntry objects to process.
 * @returns An array of reduced entries containing top 10 transactions and a summarized smaller transactions entry.
 */
/**
 * Reduces a list of entries for API consumption by keeping the top 10 most expensive transactions
 * and aggregating the rest into a single "Smaller Transactions" entry.
 *
 * @param entries - Array of CombinedEntry objects to process.
 * @returns An array of reduced entries containing top 10 transactions and a summarized smaller transactions entry.
 */
function reduceEntriesForAPI(entries: CombinedEntry[] | undefined): {
  date: string | null;
  name: string;
  amount: number;
}[] {
  if (!entries || entries.length === 0) {
    return [];
  }
  if (entries.length <= 100) {
    // Directly map if the entries are within the threshold
    return entries.map(({
      date,
      name,
      amount
    }) => ({
      date: date ?? todayStringDate,
      // Normalize undefined to null
      name: name ?? 'Entry',
      // Provide a default for undefined names if necessary
      amount: amount ?? 0 // Default to 0 if amount is undefined (optional based on your data)
    }));
  }

  // Initialize the top 10 array with the first 10 entries
  const top10: CombinedEntry[] = entries.slice(0, 10).sort((a, b) => (a.amount || 0) - (b.amount || 0));

  // Iterate through the remaining entries and maintain the top 10 most expensive
  for (let i = 10; i < entries.length; i++) {
    const currentEntry = entries[i];
    if (currentEntry && top10[0] && currentEntry.amount > (top10[0].amount || 0)) {
      top10[0] = currentEntry;
      // Re-sort the top 10 to maintain the min-heap property
      top10.sort((a, b) => (a.amount || 0) - (b.amount || 0));
    }
  }

  // Calculate the total of smaller transactions
  const smallerTransactionsTotal = entries.reduce((sum, entry) => {
    // Skip entries that are part of the top 10
    if (top10.some(t => t === entry)) {
      return sum;
    }
    return sum + (entry.amount || 0);
  }, 0);

  // Format the output for API consumption
  return [...top10.map(({
    date,
    name,
    amount
  }) => ({
    date: date ?? todayStringDate,
    // Normalize undefined to null
    name: name ?? 'Entry',
    // Provide a default for undefined names if necessary
    amount: amount ?? 0 // Ensure amount is always a number
  })), {
    date: todayStringDate,
    // Placeholder for aggregated entry
    name: 'Smaller Transactions',
    amount: smallerTransactionsTotal
  }];
}
const getMonthBody = (date: string, map: {
  [date: string]: CombinedEntry[];
}, outputName: string) => {
  const curMonthEntries = map[date] ?? [];
  const curMonthAmount = curMonthEntries.reduce((acc: number, cur: CombinedEntry) => acc + cur.amount, 0);
  return {
    name: `${outputName} - ${date}`,
    date: getDateStringFormat(parseUTCDateObject(date)),
    total_value: curMonthAmount,
    transactions: reduceEntriesForAPI(map[date])
  };
};
const DrawerAISuggestion = ({
  dates,
  outputID,
  outputName,
  generateSignForEntryFunction
}: {
  dates: string[];
  outputID: number;
  outputName: string;
  generateSignForEntryFunction: ((entry: CombinedEntry) => CombinedEntry) | null;
}) => {
  const isExplainerDisabled = false;
  const [aiResponse, setAiResponse] = useState<any>(null); // this is the AI response
  const [isAiWorking, setIsAiWorking] = useState<boolean>(!isExplainerDisabled); // this is the AI working state to dispaly gif or icon
  const [actualAmount, setActualAmount] = useState<number>(0); // the is the live data amounth
  const [forecastAmount, setForecastAmount] = useState<number>(0); // the is the forecast data amounth
  const [curDates, setCurDates] = useState<string[]>([]); // cur dates after formatting
  const [isLiveData, setIsLiveData] = useState<boolean>(false); // this is the live data state

  const {
    t: translate
  } = useTranslation('financialsDrawer');
  const {
    currentDate,
    isSidebarDrawerOpen
  } = useZustandStore(
  // add useShallow
  useShallow((state: ZustandState) => ({
    currentDate: state.currentDate,
    isSidebarDrawerOpen: state.isSidebarDrawerOpen
  })));
  const {
    aiExplainer
  } = useMutations();
  useEffect(() => {
    const getDateRange = (baseDate: string) => ({
      prevMonth: moment(baseDate).subtract(1, 'month').format('YYYY-MM-DD'),
      nextMonth: moment(baseDate).add(1, 'month').format('YYYY-MM-DD')
    });
    const getReferenceDates = (datesArray: string[]) => ({
      lastDate: datesArray[datesArray.length - 1] || '',
      midDate: datesArray[Math.floor(datesArray.length / 2)] || ''
    });

    // Handle date range generation
    const newCurDates = dates.length === 1 ? [getDateRange((dates[0] as string)).prevMonth, ...dates, getDateRange((dates[0] as string)).nextMonth] : dates;

    // Get reference dates for comparison
    const {
      lastDate,
      midDate
    } = getReferenceDates(dates);

    // Set live data status based on date comparison
    const referenceDate = dates.length === 1 ? midDate : lastDate;
    setIsLiveData(moment(referenceDate).isBefore(moment(currentDate)));
    setCurDates(newCurDates);
  }, [dates, currentDate]);

  /**
   * Fetches an AI-generated explainer response for the provided body data.
   *
   * @param body - The data to be sent to the AI explainer service.
   * @returns Void. The response from the AI explainer service is stored in the component state.
   */
  const getAIExplainerResponse = (body: any) => {
    if (!isSidebarDrawerOpen) return;
    setIsAiWorking(true);
    aiExplainer.mutate(body, {
      onSuccess: (data: any) => {
        setAiResponse(data.response);
        setIsAiWorking(false);
      },
      onError: () => {
        setAiResponse('AI failed to parse the data');
        setIsAiWorking(false);
      }
    });
  };
  function updateTotalValuesAndGetData() {
    const liveData = getDatesToEntriesMap(outputID, curDates, null, 'live');
    const forecastData = getDatesToEntriesMap(outputID, curDates, generateSignForEntryFunction);
    const calculateTotal = (entries: CombinedEntry[] = []) => Math.round(entries.reduce((acc, {
      amount
    }) => acc + (amount || 0), 0) * 100) / 100;
    if (dates.length > 1) {
      const [actualAmountVar, forecastAmountVar] = curDates.reduce(([actualSum, forecastSum], date) => [actualSum + calculateTotal(liveData[date]), forecastSum + calculateTotal(forecastData[date])], [0, 0]);
      setActualAmount(actualAmountVar);
      setForecastAmount(forecastAmountVar);
    } else {
      const midDate = curDates[Math.floor(curDates.length / 2)] || '';
      setActualAmount(calculateTotal(liveData[midDate]));
      setForecastAmount(calculateTotal(forecastData[midDate]));
    }
    return isLiveData ? liveData : forecastData;
  }

  /**
   * Optimizes the explainer body by grouping transactions and calculating total values for quarterly or yearly data.
   *
   * @param dates - An array of date strings representing the data periods.
   * @param dataMap - An object containing the data for each date.
   * @param outputName - The name of the output.
   * @returns The optimized explainer body, or null if the data is for a total period.
   */
  function optimizeExplainerBody(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  dates: string[], dataMap: any,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  outputName: string) {
    const dateLength = dates.length;
    const isQuarterly = dateLength === 3;
    const isYearly = dateLength === 12;
    const isTotal = dateLength > 12;
    if (isTotal) return null;
    const explainerBody = {
      input: dates.map(date => {
        const monthBody = getMonthBody(date, dataMap, outputName);
        if (isQuarterly || isYearly) {
          // Group transactions by date and sum amounts
          const groupedTransactions = monthBody.transactions.reduce((acc: any, curr: any) => {
            const transactionDate = curr.date;
            if (!acc[transactionDate]) {
              acc[transactionDate] = {
                date: transactionDate,
                name: `Grouped transactions for ${transactionDate}`,
                amount: 0
              };
            }
            acc[transactionDate].amount += curr.amount;
            return acc;
          }, {});
          return {
            name: monthBody.name,
            date: monthBody.date,
            total_value: monthBody.total_value,
            transactions: Object.values(groupedTransactions)
          };
        }
        return monthBody;
      })
    };
    return explainerBody;
  }
  useEffect(() => {
    if (curDates && outputID && !isExplainerDisabled) {
      const dataMap = updateTotalValuesAndGetData();
      if (!isExplainerDisabled) {
        const explainerBody = optimizeExplainerBody(curDates, dataMap, outputName);
        if (!explainerBody || !explainerBody.input.some(entry => entry.total_value !== 0)) {
          setAiResponse(null);
          setIsAiWorking(false);
          return;
        }

        // Reorder entries
        const defaultEntry = {
          name: 'no entries',
          date: '',
          total_value: 0,
          transactions: []
        };
        explainerBody.input = [explainerBody?.input?.[1] ?? defaultEntry, explainerBody?.input?.[0] ?? defaultEntry, ...(explainerBody.input?.slice(2) ?? [])];
        getAIExplainerResponse(explainerBody);
      } else {
        setAiResponse(null);
        setIsAiWorking(false);
      }
    }
  }, [curDates, outputID]);
  if (curDates?.length === 0) return null;
  function renderMonthAMounts() {
    const showActual = isLiveData && actualAmount;
    return <div className={styles.transactionsInfo} data-sentry-component="renderMonthAMounts" data-sentry-source-file="DrawerAISuggestion.tsx">
        <div className={styles.transactionsTotal}>
          {translate('transactions_total')}
        </div>
        <div className={styles.transactionsForcast}>
          <div>{translate('forecast')}</div>
          <span style={{
          color: showActual ? forecastAmount > actualAmount ? 'green' : 'red' : 'black'
        }}>
            {numeral(forecastAmount).format('$0,0')}
          </span>
        </div>
        {isLiveData && actualAmount !== undefined && <div className={styles.transactionsActual}>
            <div>{translate('actual')}</div>
            <span>{numeral(actualAmount).format('$0,0')}</span>
          </div>}
      </div>;
  }
  return <div className={styles.drawerAISuggestion} data-sentry-component="DrawerAISuggestion" data-sentry-source-file="DrawerAISuggestion.tsx">
      <div className={styles.drawerAISuggestionContent}>
        <div className={styles.aiResponseContainer}>
          <img src={isAiWorking ? aiGif.src : aiIcon.src} alt="aiIcon" width={24} height={24} />
          {aiResponse && !isAiWorking ? <Message id="drawerAiResponse" text={aiResponse.replaceAll('"', '').trim()} sender="bot" interactionIcons={false} messagesEndRef={null} addOptionToChat={() => {}} className={styles.aiResponse} onAnimationEnd={() => {
          setIsAiWorking(false);
        }} /> : <p>{translate('ai_is_working')}</p>}
        </div>
        <div className={styles.seperator} />
        {renderMonthAMounts()}
      </div>
    </div>;
};
export default DrawerAISuggestion;