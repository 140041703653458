import { useShallow } from 'zustand/react/shallow';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import { useMergePrompts } from '@/utils/AILogic/AIApplyPrompts';
import type { ChatState } from '@/utils/hooks/useChatStore';
import { useChatStore } from '@/utils/hooks/useChatStore';
import AIItemView from '../AIItemView';
import { EXPLAIN_AI_PROMPT } from '../aiPrompts/aiPrompts';
const AIView = () => {
  const {
    openChatWindow
  } = useChatStore(useShallow((state: ChatState) => ({
    openChatWindow: state.openChatWindow
  })));
  const {
    resetPrompts,
    removePrompt,
    prompts
  } = useZustandStore(useShallow((state: ZustandState) => ({
    prompts: state.prompts,
    resetPrompts: state.resetPrompts,
    removePrompt: state.removePrompt
  })));
  const mergePrompts = useMergePrompts();

  /**
   * Merges prompts and sets the app loader state.
   * This function is called when the user wants to merge prompts.
   * It sets the app loader state to true, calls the `mergePrompts` function,
   * and then sets the app loader state to false after the promise resolves or rejects.
   */
  const mergeItemsFunction = () => {
    mergePrompts();
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.AI_VIEW_ACCEPTED);
  };

  /**
   * Merges prompts to a new scenario and sets the app loader state.
   * This function is called when the user wants to merge prompts to a new scenario.
   * It sets the app loader state to true, calls the `mergePrompts` function with the `true` argument,
   * and then sets the app loader state to false after the promise resolves or rejects.
   */
  const mergeToNewScenarioFunction = () => {
    mergePrompts(true);
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.AI_VIEW_MERGED_TO_NEW_SCENARIO);
  };
  return <AIItemView title="AI View" items={prompts} removeItems={removePrompt} resetItems={() => {
    resetPrompts();
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.AI_VIEW_REJECTED);
  }} openChatWindow={openChatWindow} mergeItems={mergeItemsFunction} mergeToNewScenario={mergeToNewScenarioFunction} chatContentState={EXPLAIN_AI_PROMPT} data-sentry-element="AIItemView" data-sentry-component="AIView" data-sentry-source-file="AIView.tsx" />;
};
export default AIView;