import { Trash2 } from 'lucide-react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { IoMdAdd } from 'react-icons/io';
import { LuPencil } from 'react-icons/lu';
import { RxReset } from 'react-icons/rx';
import { TbSum } from 'react-icons/tb';
import type { ReportItem } from '@/utils/hooks/reports/reportTypes';
import { CulmutativeEnum } from '@/utils/hooks/reports/reportTypes';
export const getOptions = (addOutput: () => void, renameOutput: () => void, convertOutput: () => void, deleteOutput: () => void, getCovertOptionLabel = () => '', deleteText = 'Delete Account'): {
  label: string;
  onClick: () => void;
  id: string;
  icon: JSX.Element;
}[] => [{
  label: 'Add Sub-Account',
  onClick: () => addOutput(),
  id: `table-options-${0}`,
  icon: <IoMdAdd width={15} color="black" />
}, {
  label: 'Rename',
  onClick: () => renameOutput(),
  id: `table-options-${1}`,
  icon: <LuPencil width={15} color="black" />
}, {
  label: getCovertOptionLabel(),
  onClick: () => convertOutput(),
  id: `table-options-${2}`,
  icon: <BsCurrencyDollar size={15} color="black" />
}, {
  label: deleteText,
  onClick: () => deleteOutput(),
  id: `table-options-${3}`,
  icon: <Trash2 size={15} color="black" />
}];
export const getUpdateCulmutativeTypeOptions = (updateCulmutativeType: (culmutativeType: CulmutativeEnum | null) => void) => [{
  label: 'Culmutative: start of the month',
  onClick: () => updateCulmutativeType(CulmutativeEnum.Start),
  id: `table-options-${4}`,
  icon: <TbSum size={15} color="black" />
}, {
  label: 'Culmutative: end of the month',
  onClick: () => updateCulmutativeType(CulmutativeEnum.End),
  id: `table-options-${5}`,
  icon: <TbSum size={15} color="black" />
}, {
  label: 'Reset Culmutative Total',
  onClick: () => updateCulmutativeType(null),
  id: `table-options-${6}`,
  icon: <RxReset size={15} color="black" />
}];
export const departments = ['gna', 'rnd', 'snm', 'cogs']; // temporary until depratments are pulled from hiring plan
/** This function Groups Report items by parent id ad sorts them by parent sortOrder string */
export const groupItemsByParent = (items: ReportItem[], initialOrder: string): {
  [key: number]: ReportItem[];
} => {
  const groupedItems: {
    [key: number]: ReportItem[];
  } = {};
  const sortOrders: {
    [key: number]: string;
  } = {};
  sortOrders[0] = initialOrder; // Sort root items based on initialOrder

  items.forEach(item => {
    const parentId = item.parent_id ?? 0; // Use 0 for root items
    groupedItems[parentId] = groupedItems[parentId] || [];
    groupedItems[parentId]?.push(item);
    sortOrders[item.id] = item.sort_order || '';
  });

  // Sort items within each group based on parent's sortOrder
  for (const [parentId, curItems] of Object.entries(groupedItems)) {
    const sortOrder = sortOrders[Number(parentId)];
    if (sortOrder) {
      const idsToSort = sortOrder.split('|').map(id => parseInt(id, 10));
      curItems.sort((a, b) => idsToSort.indexOf(a.id) - idsToSort.indexOf(b.id));
    }
  }
  return groupedItems;
};