/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { ApideckVault } from '@apideck/vault-js';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import { BASE_URL } from '@/utils/AppConfig';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { useIsLoggedIn } from '@/utils/hooks/useIsLoggedIn';
import { errorToaster } from '@/utils/toaster/toasters';

// src/utils/helpers.ts

/**
 * Generates a request body object for creating a new integration in the Apideck Vault.
 *
 * @param connection - The connection object containing details about the integration.
 * @param activeCompanyId - The ID of the active company.
 * @returns An object containing the necessary fields for the integration creation request.
 */
const getBody = (connection: any, activeCompanyId: number): {
  company_id: number;
  type: string;
  integration_provider: string;
  realm_id: number;
  created_by: number;
  service_id: string | undefined;
} => ({
  company_id: activeCompanyId,
  type: connection?.id.includes('crm') ? 'CRM' : connection?.id.includes('hris') ? 'HR' : 'ACCOUNTING',
  integration_provider: 'API_DECK',
  realm_id: connection.settings?.realm_id,
  created_by: 1,
  service_id: connection?.service_id
});
function handleUpdateIntegration(connection: any, activeCompanyId: number, updateIntegration: any, integrationID: number, consumer_id: string) {
  const {
    service_id,
    realm_id,
    type,
    integration_provider,
    company_id
  } = getBody(connection, activeCompanyId);
  updateIntegration.mutateAsync({
    id: integrationID,
    service_id,
    realm_id,
    type,
    integration_provider,
    consumer_id,
    company_id
  }, {
    onSuccess: () => {
      toast.success('Integration has been re-authorized successfully, you can now call resync', {
        position: 'top-right'
      });
    }
  }).finally(() => {
    ApideckVault.close();
  });
}
function handleCreation(activeCompanyId: number, connection: any, consumer_id: string, createIntegrationMutation: any) {
  createIntegrationMutation.mutateAsync({
    ...getBody(connection, activeCompanyId),
    consumer_id
  }, {
    onSuccess: () => {
      toast.success('Integration has been created successfully, this will take a few minutes', {
        position: 'top-right'
      });
    },
    onError: () => {
      errorToaster('Something went wrong, our team has been notified', {
        position: 'top-right'
      });
    }
  }).finally(() => {
    ApideckVault.close();
  });
}
interface ApiDeckVaultSessionReturnSchema {
  session_uri: string;
  session_token: string;
  consumer_id: string;
}

/**
 * Provides a hook that opens the Apideck Vault integration and creates a new integration if the connection is enabled and callable.
 *
 * @param url - An optional URL to use for the Apideck Vault integration. If not provided, it will use the `BASE_URL` with the `/api/v1/` part removed.
 * @param consumer_id - An optional consumer ID to use for the Apideck Vault integration if it needs re-authorization
 * @param integrationID - An optional integration ID to use for the Apideck Vault integration if it needs re-authorization / resync
 * @returns A function that can be called to open the Apideck Vault integration with the specified parameters.
 */
export const useOpenVault = (url: string = '', integrationID: number | undefined = undefined) => {
  url = url || BASE_URL?.replace('/api/v1/', '') || '';
  const {
    isLoggedIn
  } = useIsLoggedIn();
  const {
    updateIntegration,
    createIntegration
  } = useMutations();
  const activeCompanyId = useZustandStore(useShallow((state: ZustandState) => state.activeCompany?.id ?? null));
  const {
    data
  } = useQuery<ApiDeckVaultSessionReturnSchema>({
    queryKey: [QUERYKEYS.API_DECK_VAULT_TOKEN, activeCompanyId],
    queryFn: () => ApiUtils.getApideckVaultToken(activeCompanyId),
    enabled: !!activeCompanyId
  });

  /**
   * Opens the Apideck Vault integration and creates a new integration if the connection is enabled and callable.
   *
   * @param serviceId - The ID of the service to open the Vault for.
   * @param unifiedApi - The unified API to use for the Vault integration.
   * @param onFinish - An optional callback function to be called when the integration is successfully created.
   * @returns The Apideck Vault integration.
   */
  const openVault = useCallback(async (serviceId: string = 'quickbooks', unifiedApi: string = 'accounting', onFinish?: () => void) => {
    if (!data) return;
    const token = data.session_token;
    const consumer_id = data?.consumer_id || '';
    if (!consumer_id) console.error('No consumer_id found on openVault call');
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.INTEGRATION_VAULT_WAS_OPENED);
    ApideckVault.open({
      showAttribution: false,
      token,
      serviceId,
      unifiedApi,
      onConnectionChange: connection => {
        if (connection.enabled === true && connection.state === 'callable') {
          if (integrationID) {
            // handle re-authorization
            handleUpdateIntegration(connection, activeCompanyId, updateIntegration, integrationID, consumer_id);
          } else {
            // handle creation
            handleCreation(activeCompanyId, connection, consumer_id, createIntegration);
          }
          onFinish?.();
        }
      },
      onReady: () => {
        mixpanelTrackEventWrapper(MIXPANEL_EVENTS.INTEGRATION_VAULT_FINISHED_SUCCESSFULLY);
      },
      onClose: () => {
        mixpanelTrackEventWrapper(MIXPANEL_EVENTS.INTEGRATION_VAULT_WAS_REJECTED);
      }
    });
  }, [url, isLoggedIn, activeCompanyId, data, updateIntegration, createIntegration]);
  return openVault;
};